/* SIDEBAR CSS */
.sidebar {
    max-width: 320px;
    background-color: #1f1f1f; /* Dark background */
    color: #fff; /* White text */
    padding: 20px;
    box-sizing: border-box;
    border-right: 0.5px solid #333;
    width: 100%;
  }
  
  .sidebar a {
    display: flex;
    align-items: center; /* Vertically centers the content in the line */
    justify-content: flex-start; /* Aligns items to the start of the container */
    color: #fff; /* White text */
    padding: 10px 15px; /* Add padding as needed */
    text-decoration: none;
    margin-bottom: 10px;
    border-radius: 15px;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  
  .sidebar a:hover {
    background-color: #4add80; /* Green highlight */
  }
  
  /* Highlight for the active link, replace .active with the class you use for indicating the active page */
  .sidebar .active {
    background-color: rgba(74, 221, 128, 0.2); /* Green with 10% opacity */
    border-radius: 15px;
  }
  
  .sidebar-link {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust the spacing as needed */
    font-size: 1.1em;
    font-weight: bold;
  }
  
  
  .link-icon {
    margin-right: 10px; /* Adds some space between the icon and text */
    width: 35px; /* Or any size that fits your design */
    height: 35px; /* Or any size that fits your design */
  }
  
  
  .sidebar .plan-status {
    margin-top: 20px;
    border-top: 1px solid #333;
    padding-top: 10px;
    text-align: left;
  }
  
  .sidebar .plan-status span {
    display: block; /* Each stat on new line */
    color: #aaa; /* Subdued text color */
    margin-bottom: 5px; /* Spacing between stats */
  }
  
  /* Style for the main 'Create' or 'Process' button */
  .sidebar .create-button {
    background-color: #45c072; /* Green background */
    color: #fff; /* White text */
    padding: 14px 15px;
    text-align: center;
    display: block;
    border-radius: 4px;
    margin-bottom: 20px; /* Spacing after the button */
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3em;
    border-radius: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  }
  
  .sidebar a:hover {
    background-color: rgba(74, 221, 128, 0.2); /* Green with 10% opacity */
    border-radius: 15px;
  }
  .plan-section {
    margin-top: 50px; /* Adjust the value as needed */
  }
  /* Style for the section titles like 'Zaps', 'Tables', etc. */
  .sidebar .section-title {
    color: #aaa; /* Subdued text color */
    margin-top: 30px; /* Spacing above the title */
    margin-bottom: 10px; /* Spacing below the title */
    font-weight: bold;
    
  }
  
  .section-title {
    display: flex;
    align-items: center;
  }
  .sidebar .manage-plan {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    display: inline;
    background-color: transparent; /* Prevent any background on hover */
    transition: none; /* Disable the transition effect */
    margin-left: 0; /* Ensures there's no margin pushing it from the left */
    padding-left: 0;
  }
  
  .sidebar .manage-plan:hover {
    background-color: transparent; /* Override the global hover effect */
    box-shadow: none; /* Remove if there's any box-shadow applied */
  }
  


  .non-clickable-link {
    opacity: 0.5; /* Make it faded */
    pointer-events: none; /* Make it non-clickable */
  }
  


  
  .dividersides {
    border-bottom: 2px solid #333;
    margin: 10px 0;
  }
  
  .section-icon {
    width: 25px; /* Adjust to match your design */
    height: 25px; /* Adjust to match your design */
    margin-right: 4px; /* Space between icon and title text */
  }
  
  .plan-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .included-time-label {
    font-weight: bold;
  }
  
  .progress-bar {
    background-color: #333;
    border-radius: 4px;
    overflow: hidden; /* Makes sure the inner progress doesn't overflow */
    position: relative; /* Needed to position the inner progress element */
    height: 7px;
    margin-bottom: 30px;
  }
  
  .progress {
    background-color: #4add80;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: width 0.5s ease; /* Smooth transition for the progress bar */
  }
  


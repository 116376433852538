
.metasub-container {
  display: flex;
  background-color: #161616;
  min-height: 100vh;

}



.subscription-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
    color: #FFFFFF;
    background-color: #161616;
    padding: 20px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
  }
  
  .subscription-page-container h1 {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }
  
  .subscription-plan-box {
    width: 320px;
    margin: auto;
    background-color: #2c2c2c;
    border-radius: 5px;
    padding: 30px;
    position: relative;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    margin-bottom: 40px;
    margin-top: 40px;
    border: 1px solid #3e3e3e;

  }
  
  .active-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #181818;
    color: #4add80;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: 20px;
    margin-right: 10px;
  }
  

  .active-badge-canceled {
    background-color: #FFC1C1; 
    color: #333333; 
    
  }
  .red-text {
    color: #FFC1C1;
  }
  


  .plan-title {
    margin-top: 0px;
    margin-bottom: 25px;
    font-size: 1.7rem;
  }
  
  .upload-credits-label {

    font-weight: bold;
    
  }

  .plan-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .upload-credits-time {
    margin-left: auto;
    font-weight: bold;
    font-size: 14px;
  }
  .upload-time-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  

  .rebuy-text-spacing {
    margin-top: 40px; 
  }
  
  
  .add-more-btn {
    padding: 15px 25px;
    background-color: #0e0e0e; /* Bright, noticeable green color */
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.0rem;
    width: 100%; /* Full-width for better mobile responsiveness */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    text-decoration: none; /* Avoids text underline on hover */
    border: 1px solid #3e3e3e;
  }
  
  .add-more-btn:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Lighter shade on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
  }
  
  
  .plan-title, .rebuy-text {
    text-align: left;
  }
  
  .info-icon {
    font-weight: bold;
  }

  .info-iconplan {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    transition: transform 0.2s; /* Animation effect */
    margin-left: 5px;
  }
  
  .info-iconplan:hover {
    transform: scale(1.1); /* Increase the size by 10% on hover */
  }

  
  .sub-tooltip-text {
    visibility: hidden;
    position: absolute;
    bottom: 110%;
    left: 70%;
    transform: translateX(-50%);
    background-color: #181818;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1;
    transition: opacity 0.5s ease-in-out;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    width: 180px;
  }
  
  .info-icon:hover .sub-tooltip-text {
    visibility: visible;
    opacity: 1;
  }




  .billing-box {
    
    width: 320px;
    margin: auto;
    background-color: #2c2c2c;
    border-radius: 5px;
    padding: 30px;
    position: relative;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    margin-top: 10px;
  }
  
  .billing-label {
    margin-right: 10px;
  }
  
  .billing-info {
    font-weight: bold;
  }
 
  .invoice-update-btn {
    padding: 15px 20px;
    background-color: #181818;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.0rem;
    width: 100%; /* Ensure responsiveness */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* subtle shadow */
    text-decoration: none; /* Override any global styles */
    border: 1px solid #3e3e3e;

  }
  
  .invoice-update-btn:hover {
    background-color: rgba(255, 255, 255, 0.08); /* Subtle change on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
  }
  
  .invoice-update-btn:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); /* Soft focus ring */
  }
  
  .invoice-update-btn:active {
    background-color: rgba(255, 255, 255, 0.12); /* Even lighter on active */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reset shadow on active */
  }
  
  
  .billing-box .plan-details {
    margin-top: 15px;  /* Add more top margin */
    margin-bottom: 15px;  /* Add more bottom margin */
  }
  
  .billing-box .invoice-update-btn {
    margin-top: 20px;  /* Add more top margin */
  }
  

  @media only screen and (max-width: 768px) {
    
    .subscription-page-container {
      width: 95%; 
        margin-bottom: 10px;
        padding: 10px;
    }


    .subscription-plan-box, .subscription-plan-box.billing-box {
      width: 80%;  
    }


    .tooltip-text {
      margin-bottom: 10px;
      margin-left: -70px;
      width: 180px;
    }


  }
.close-sidebar {
    max-width: 80px;
    background-color: #1f1f1f;
    color: #fff;
    padding: 20px 0px 20px 0px; /* Adjusted padding for consistent spacing */
    box-sizing: border-box;
    border-right: 0.5px solid #333;
    text-align: center;
    width: 100%;
}

.close-create-button {
    background-color: #45c072;
    color: #fff;
    padding: 0;
    text-align: center;
    display: block;
    border-radius: 20%;
    font-size: 1.5em;
    font-weight: bold;
    width: 40px;
    height: 40px;
    line-height: 35px;
    text-decoration: none;
    margin: 0 auto 10px; /* Auto margin for horizontal centering */
}

.close-dividersides {
    border-bottom: 2px solid #333;
    margin: 20px 0; /* Increased margin to match the top and bottom padding of sidebar */
}

.close-sidebar-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    background-color: transparent;
    width: 100%;
    height: 50px; /* Adjust height as needed */
    padding-left: 0; /* Make sure padding does not cause issues */
    padding-right: 0; /* Make sure padding does not cause issues */
}

.close-link-icon {
    transition: background-color 0.2s;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.close-link-icon-selected {
    padding-left: 0; /* Extend the background to the full width */
    padding-right: 0; 
}

.close-link-selected {
    background-color: rgba(0, 0, 0, 0.4); /* Darkened background for the whole link */
    
}

.close-link-selected::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #45c072; /* Your green color */
}


.non-clickable-link {
    opacity: 0.5; /* Make it faded */
    pointer-events: none; /* Make it non-clickable */
  }




  @media (max-width: 1280px) {
    .close-sidebar {
        width: 100px; /* Narrower sidebar for smaller screens */
        padding: 15px 0px; /* Adjust padding */
        width: 100%;
    }

    .close-create-button {
        width: 30px; /* Smaller button */
        height: 30px;
        line-height: 25px;
        font-size: 1em; /* Smaller font size */
    }

    .close-dividersides {
        margin: 15px 0; /* Adjusted margin */
    }

    .close-sidebar-link {
        height: 40px; /* Adjust height */
    }

    /* ... other responsive adjustments ... */
}


@media (max-width: 768px) {
    .close-sidebar {
        width: 70px; /* Narrower sidebar for smaller screens */
        padding: 15px 0px; /* Adjust padding */
    
    }

  }
.cookie-bar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    width: 100%;
    background-color: #0e0e0e;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .accept-cookies-btn {
    background-color: #3da6fe;
    font-size: 17px;
    font-weight: bold; 
    color: white;
    border-radius: 10px;
    padding: 15px 25px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease; 
  }
  
  .accept-cookies-btn:hover {
    background-color: #2f94e5;  /* Slightly darker blue on hover */
  }

  .later-btn {
    background-color: transparent;
    color: white;
    font-weight: bold;  /* Make the text bold */
    font-size: 17px;
    padding: 15px 25px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
    margin-right: 40px; 
  }
  
  .later-btn:hover {
    background-color: #9b9ea3;/* Change background color on hover */
    color: white;
  }
  

  .cookie-text {
    word-wrap: break-word;
    overflow-wrap: break-word;
    flex-grow: 1;
    text-align: left;
    max-width: 100%;  /* Add this line */
    margin-left: 40px;
    font-weight: bold;
  }
  

  .cookie-text a {
    color: inherit;  /* To match the color of the surrounding text */
    text-decoration: underline;  /* To indicate it's a clickable link */
  }
  

  @media (max-width: 768px) {
    .cookie-bar {
      flex-direction: column;
      align-items: flex-start;
      padding: 5px;  /* Reduce padding */
      overflow: auto;
    }
  
    .cookie-text {
      margin-bottom: 10px;
      max-width: calc(100% - 10px);
      margin-left: 10px;
    }
    .button-wrapper {
        display: flex;  /* Add this line */
        width: 100%;  /* Add this line */
        justify-content: center;  /* Add this line */
      }
    
      .accept-cookies-btn, .later-btn {
        margin: 7px;  /* Add margin around each button */
      }


  }
  
  
.plan-popup-wrapper {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
  }
  

.plan-popup-container {
    background-color: #0e0e0e;
    border: none;
    border-radius: 5px;
    padding: 24px;
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    border: 1px solid #3e3e3e;
    transform: translate(-50%, -50%);
    z-index: 50;
  }
  
  .close-btn {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .essential-info {
    background-color: #282828;
    border-radius: 16px;
    padding: 17px;
    margin-top: 10px;
    text-align: left;
  }
  
  .proceed-btn {
    background-color: #45c072;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 15px 25px;
    cursor: pointer;
    margin-top: 15px;
    font-weight: bold;
    font-size: 1.0em;
    border: 1px solid #3e3e3e;

  }

  .proceed-btn:hover {
    background-color: #3faf68;
  }
  
  select {
    background-color: #181818;
    color: #ffffff;
    border: none;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
    appearance: none;
    font-weight: bold;
  
  }
  

  .plan-choice-select {
    background-color: #282828;
    color: #ffffff;
    border: none;
    padding: 12px;
    border-radius: 8px;
    margin-top: 10px;
    width: 100%;
    padding-right: 30px; 
    appearance: none;
    font-weight: bold;
    outline: none; 
  
  }
.minutes-select {
  outline: none; 

}
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 49;
  }


  .plan-popup-image {
    width: 700px;
    height: auto;
    border-radius: 12px;
    margin-left: 950px;
  }

  .select-wrapper {
    position: relative;
  }
  .select-wrapper::after {
    content: "\25BC";  /* Unicode for arrow down */
    position: absolute;
    top: 60%;
    right: 10px;  /* Adjust this to move the arrow */
    transform: translateY(-50%);
    pointer-events: none;  /* Makes click events pass through */
  }
  



  .monthly-text {
    margin-top: -1px;
    font-weight: bold;
    font-size: 1.7em;
    color: #ffffff;
  }
  
  .price-text {
    font-weight: 600;
    font-size: 2.1em;
    color: #ffffff;
  }
  
  .upload-minutes-text {
    font-weight: 500;
    font-size: 1.3em;
    color: #4add80;
    font-weight: bold;
    
  }
  
  .expiry-info-text {
    font-size: 0.9em;
    color: #a0a0a0;
  }
  
  .additional-info {
    max-height: 90px;
    margin-top: 20px;
    overflow-y: auto;
  }
  
  .billing-info-text,
  .rebuy-info,
  .current-credits-info,
  .new-plan-info {
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
    color: #a0a0a0;
  }
  
  
  .additional-info::-webkit-scrollbar {
    width: 5px;
  }
  
  .additional-info::-webkit-scrollbar-thumb {
    background: #a0a0a0; /* You can choose your own color for the thumb */
    border-radius: 5px;
  }
  
  .additional-info::-webkit-scrollbar-track {
    background: #282828;
    border-radius: 5px;
  }

  .popup-header {
    font-size: 30px;
    color: white;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: left;
  }
  

  .paiduser-proceed-btn {
    background-color: #45c072;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 15px 25px;
    cursor: pointer;
    margin-top: 15px;
    font-weight: bold;
    font-size: 1.0em;

  }

  .paiduser-proceed-btn:hover {
    background-color: #5bbc7b; /* Lighter shade of green for a subtle hover effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
  }

  .bumpup-logo {
    vertical-align: middle;
    margin-left: 3px;        /* Adds space between the text and the image */
    margin-bottom: 3px;
    height: 25px;            /* Optional: Sets the height of the image */
    width: auto;             /* Keeps the aspect ratio of the image */

  }

  @media only screen and (max-width: 768px) {
    .plan-popup-wrapper, .plan-popup-container {
      width: 90%;  /* Adjust this value as needed */
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .popup-header {
      font-size: 20px;

    }


    
  }
  
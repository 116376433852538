.creator-info-container {
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    background-color: #161616;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;

  }
  
  .creatorinfo-ALL-container {
    display: flex;
    flex-direction: row; /* Keep items in a row */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    box-sizing: border-box;
    width: 70vw;
    gap: 30px;
  }


    /* CREATOR BOX */
    /* CREATOR BOX */
    /* CREATOR BOX */
    /* CREATOR BOX */
  
    .creator-info-box {
        background: #161616;
        color: #fff;
        padding: 20px 30px;
        border-radius: 5px;
        display: flex;
        align-items: center; /* Align items vertically center */
        max-width: 450px;
        border: 1px solid #3e3e3e;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
        
    }
    
    .creator-avatar-section {
        margin-right: 25px; /* Add space between avatar and subscription section */
        flex-grow: 0;
    }
    
    .creator-avatar {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 5px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
        /* border: 1px solid #3e3e3e; */

    }
    
    .channel-title {
        color: #fff;
        font-size: 1.5em;
        margin-bottom: 0.5em;
        align-self: flex-end;
    }
    
    .channel-name {
        color: #aaa;
        font-size: 1.1em;
        align-self: flex-end;
    }
    
    .creator-subscription-section {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1; /* Allows this section to fill the remaining space */
        margin-left: 15px;
    }
    
    .subscriber-title {
        color: #fff;
        font-size: 1.2em;
        margin-bottom: 0.4em;
    }
    
    .subscriber-count {
        color: #fff;
        font-size: 1.6em;
        margin-top: 0;
    }

    
    .creatorpage-connect-button {
        background-color: #54565c;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        padding: 20px 10px;
        color: white;
        cursor: pointer;
        margin-top: 40px;
        display: flex; /* To align the image and text inline */
        align-items: center; /* Center the items vertically */
        justify-content: center; /* Center the items horizontally */
        opacity: 0.8;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

      }

      .creatorpage-connect-button.connected {
        cursor: default;
        background-color: #ffffff; /* Dark gray background */
        opacity: 1.0;
    } 


      .create-connectyoutube {
        width: 41px;
        height: 31px;
      }
      
      
  

    /* STATS BOX */
    /* STATS BOX */
    /* STATS BOX */
    /* STATS BOX */


    .creator-stats-box {
        background: #161616;
        color: #fff;
        padding: 20px 20px;
        border-radius: 5px;
        max-width: 350px;
        
         border: 1px solid #3e3e3e; 
         box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1); 

        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the start of the container */
    }
    
    .creator-stats-header {
        display: flex;
        align-items: center; /* Vertically centers the items */
        justify-content: flex-start; /* Aligns items to the start of the container */
        width: 100%;
        margin-bottom: 20px; /* Space between the header and the stats */
    }
    

    .reload-button {
        background-color: #161616; /* Same as .search-button */
        border: none;
        color: #ffffff;
        padding: 7px 7px; /* Adjust padding as needed to accommodate the image */
        border-radius: 8px;
        cursor: pointer;
        font-weight: bold;
        margin-left: 30px;
        transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Add background-color to the transition */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
        border: 1px solid rgba(62, 62, 62, 0.5); /* Border with 0.5 opacity */

    }
    
    .reload-button img {
        width: 25px;
        height: 25px;
        object-fit: contain; /* This ensures the image maintains its aspect ratio without being cropped. */
    }
    
    .reload-button:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
        background-color: rgba(74, 221, 128, 0.5); /* Slight green with transparency */
    }
    


    .creatorpage-analytics-logo {
        width: 50px; /* Adjust as needed */
        height: auto;
        margin-right: 5px; /* Adds space to the right of the logo */
    }
    
    .stats-title {
        font-size: 20px; /* Adjust as needed */
        color: #ffffff; /* Adjust color as needed */
    }
    



        .stats-grid {
            display: grid;
            grid-template-columns: 1fr 1fr; /* Split the grid into two equal columns */
            align-items: center;
            width: 100%;
            text-align: left; /* Align text to the left for the whole grid */
            margin-top: 10px; /* Adjust the margin as needed */
        }

        .stats-header { /* One class for all stat headers */
            font-size: 16px; /* Adjust as needed */
            color: #cccccc; /* Adjust color as needed */
            grid-column: 1; /* Place in the first column */
        }

        .stats-value { /* One class for all stat values */
            font-size: 16px; /* Adjust as needed */
            color: #ffffff; /* Adjust color as needed */
            grid-column: 2; /* Place in the second column */
            justify-self: end; /* Align to the end of the grid cell */
        }

        .stats-summary-title { /* Class for the summary title */
            font-size: 15px; /* Adjust as needed */
            color: #ffffff; /* Adjust color as needed */
            grid-column: 1 / -1; /* Span across all columns */
            align-self: start; /* Align to the start of the grid */
            margin-bottom: 10px; /* Space below the summary title */
        }


   /* SETTINGS BOX */
   /* SETTINGS BOX */
   /* SETTINGS BOX */
   /* SETTINGS BOX */

   .creatorlogged-settings-wrapper {
    display: flex;
    flex-direction: column; /* Stack the children vertically */
    align-items: center; /* Center the children horizontally */
    gap: 20px;
   }
        .creator-info-container {
            display: flex; /* Establishes a flex container */
            align-items: stretch; /* Stretches the children to fill the container height */
            /* Add other styles as needed */
        }
        
        .creator-settings-box {
            background: #161616;
            color: #fff;
            border-radius: 5px;
            display: flex; /* You can also use flexbox inside this container */
            justify-content: center; /* Aligns items horizontally center */
        }
        
        .creatorpage-settings-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #161616; /* Dark color for a neutral look */
            padding: 10px 10px;
            color: white;
            border: 1px solid #3e3e3e;
            border-radius: 5px;
            font-weight: bold;
            font-size: 1.0rem;
            cursor: pointer;
            margin-right: 5px; /* Adjust the spacing as needed */
            transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover and shadow */
            width: 30px;
            height: 30px;
        }

        .creatorpage-settings-button:hover {
            background-color: #0e0e0e; /* Slightly lighter color on hover */
            box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.1); /* More pronounced shadow on hover */
            transform: scale(1.02);
          }

        .creator-settings-icon {
            width: auto; /* Or define a specific size */
            height: 45px; /* Match with your desired size */
            /* Add other styles as needed */
          }


          .autocomment-settings-box {
            background: #161616;  
          }
          
          .autocomment-settings-button {
            position: relative; /* Required for absolute positioning of the pseudo-element */
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #161616;
            padding: 25px 25px;
            color: white;
            border-radius: 5px;
            font-weight: bold;
            font-size: 1.0rem;
            cursor: pointer;
            margin-right: 5px;
            transition: background-color 0.3s ease, box-shadow 0.3s ease;
            width: 30px;
            height: 30px;
          }
          
          .autocomment-settings-button:hover::after {
            content: attr(data-hover-text);
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-15%);
            background-color: #fff; /* Background of the tooltip */
            color: #000; /* Text color of the tooltip */
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 0.8rem;
            white-space: nowrap;
            margin-top: 10px; /* Space between button and tooltip */
            z-index: 10; /* Ensure it's above other items */
            opacity: .7;
          }
          
          .autocomment-settings-button-green {
            border: 2px solid rgba(74, 221, 128, 0.4);
            opacity: 1;

        }
          
          .autocomment-settings-button-red {
            border: 2px solid rgba(62, 62, 62, 0.4);
            opacity: .7;
          }
          
          
          .autocomment-settings-icon {
            width: auto; /* Or define a specific size */
            height: 35px; /* Match with your desired size */          
        
        }
          
  


            /* Mobile optimization */
            @media (max-width: 768px) {
                .creator-info-container {
                    flex-direction: column; /* Stack elements vertically */
                    align-items: center;
                    margin-top: 30px;
                    margin-bottom: 20px;

                }

                .creator-settings-box {
                    display: none;                

                    }

                .channel-name {
                    margin-bottom: 2px;
                }


                .creator-avatar {
                    width: 100px; /* Reduce avatar size */
                    height: 100px;
                }


                .creatorlogged-settings-wrapper {

                    display: none;
                }

                .creator-stats-box {

                    display: none;
                }

                .creator-subscription-section {
                   display: none;
                }

                .creator-avatar-section {
                    margin: 5px 0; /* Adjust margins for a more compact layout */
                    text-align: center; /* Center text */
                }

                .subscriber-title,
                .subscriber-count,
                .stats-title,
                .stats-header,
                .stats-value {
                    font-size: smaller; /* Reduce font size for readability */
                }
            }

            /* Tablet optimization */
            @media (min-width: 769px) and (max-width: 1280px) {
                /* Adjustments for tablet devices, potentially different from mobile but not as large as desktop */
        
                    .creator-stats-box {

                        display: none;
                    }

            }



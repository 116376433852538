.creator-calendar-container {
  display: flex;
  flex-direction: column;
  background-color: #161616; /* Dark background as per the image */
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%; /* Example: responsive width */
  margin-left: auto; /* Centering the calendar */
  margin-right: auto; /* Centering the calendar */  
}


.creator-ALL-content {

  width: 70vw; /* Take up the entire viewport width */
  margin-left: auto; /* Centering the calendar */
  margin-right: auto; /* Centering the calendar */
}

/* YEAR & SEARCH SELECTOR */

.creator-year-and-search-wrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-left: auto; /* Centering the calendar */
  margin-right: auto; /* Centering the calendar */
}


/* YEAR SELECTOR */
/* YEAR SELECTOR */
/* YEAR SELECTOR */
/* YEAR SELECTOR */

.creator-year-selector {
  display: flex;
  align-items: center;
  color: white;
  flex-grow: 0;
}

.creator-year-button {
  background-color: #161616;
  color: white;
  padding: 5px 10px;
  font-size: 22px;
  border: none;
  cursor: pointer;
}

.creator-year-button:focus {
  outline: none;
}

.creator-year-button:hover {

  color: #4add80; /* Updated color for active tab text */ 
  
}

.creator-year-display {
  min-width: 60px;
  text-align: center;
  font-size: 20px;
  color: white;
  background-color: #161616;
  padding: 5px 10px;
  user-select: none;
}


/* REALOD CATALOG BUTTON */
/* REALOD CATALOG BUTTON */
/* REALOD CATALOG BUTTON */
/* REALOD CATALOG BUTTON */

  .creator-delete-button {
    flex-grow: 0;
    margin-left: 20px;
  }
  .creator-update-button {
    flex-grow: 0;
    margin-left: 20px;

  }

  .delete-array-button {
    display: flex;
    align-items: center;
    background-color: #161616; /* Same as .search-button */
    border: none;
    color: #ffffff;
    padding: 7px 7px; /* Adjust padding as needed to accommodate the image */
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    margin-left: 4px;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Add background-color to the transition */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
    border: 1px solid rgba(62, 62, 62, 0.5); /* Border with 0.5 opacity */

  }

  .delete-array-button img {
    width: 22px;
    height: 22px;
    object-fit: contain; /* This ensures the image maintains its aspect ratio without being cropped. */
  }

  .delete-array-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
    background-color: rgba(255, 71, 71, 0.4); /* Slight red with transparency */
  }

  .delete-array-button:hover::after {
    content: attr(data-hover-text);
    position: absolute;
    top: -30px;  /* Adjust the top value to position the tooltip above the button */
    left: 10%;
    transform: translateX(-50%);
    background-color: #fff; /* Background of the tooltip */
    color: #000; /* Text color of the tooltip */
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    white-space: nowrap;
    z-index: 10; /* Ensure it's above other items */
    opacity: 0.7;
    pointer-events: none; /* Prevent tooltip from being interactive */
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1);  /* Optional: add shadow for better visibility */
  }
  

  
  .reload-catalog-text {
    margin-left: 4px;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
  }


.update-array-button {
  display: flex;
  align-items: center;
  background-color: #161616; /* Same as .search-button */
  border: none;
  color: #ffffff;
  padding: 7px 7px; /* Adjust padding as needed to accommodate the image */
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 4px;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Add background-color to the transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border: 1px solid rgba(62, 62, 62, 0.5); /* Border with 0.5 opacity */


}

.update-array-button img {
  width: 22px;
  height: 22px;
  object-fit: contain; /* This ensures the image maintains its aspect ratio without being cropped. */
}

.update-array-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  background-color: rgba(74, 221, 128, 0.4); /* Slight red with transparency */
}

.update-array-button:hover::after {
  content: attr(data-hover-text);
  position: absolute;
  top: -30px; /* Adjust this value to position the tooltip above the button */
  left: 0%; /* Align the tooltip to the left side of the button */
  transform: translateX(0%); /* No horizontal offset */
  background-color: #fff; /* Background of the tooltip */
  color: #000; /* Text color of the tooltip */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 10; /* Ensure it's above other items */
  opacity: 0.7;
  pointer-events: none; /* Prevent tooltip from being interactive */
}



/* SEARCH BAR */
/* SEARCH BAR */
/* SEARCH BAR */
/* SEARCH BAR */

.creator-youtube-search {
  
  flex: 1; /* Take up all available space */
  display: flex;
  justify-content: flex-end; /* Align items to the right */

}

.creator-search-bar-wrapper {
  background-color: #161616;
  border-bottom: 0.5px solid #333;
  /* border-radius: 8px; */
  padding: 7px 16px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  max-width: 400px;
  width: 400px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1); */
}

.creator-search-input {
  background-color: #161616;
  border: none;
  color: #ffffff;
  flex-grow: 1;
}

.creator-search-button {
  background-color: #161616;
  border: none;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 0px;
  border: 1px solid #3e3e3e;

}

.creator-search-input:focus {
  outline: none;
  box-shadow: none;
}

.creator-search-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}
.search-error {
  background-color: rgba(255, 71, 71, .7); /* Slight red with transparency */
}


.disabled-content {
  opacity: 0.4; /* Lower opacity to indicate disabled state */
  pointer-events: none; /* Prevents all click, state and cursor options */
}



/* MONTH TABS */
/* MONTH TABS */
/* MONTH TABS */
/* MONTH TABS */


.creator-month-tabs {
  display: flex;
  align-items: center;
  padding: 0;
  list-style-type: none;
  background-color: #161616; /* Match the background color to your theme */
  border-bottom: 0.5px solid #333;
  margin: auto; 
  
}

.creator-month-tab {
  color: #BBB; /* Non-active tab color */
  padding: 10px 20px;
  font-size: 20px;
  border: none;
  background-color: inherit;
  cursor: pointer;
  transition: color 0.3s; /* Smooth transition for hover effect */
  border-bottom: 3px solid transparent; /* Non-active tabs */
  margin-left: auto; /* Centering the calendar */
  margin-right: auto; /* Centering the calendar */
}

.creator-month-tab:hover {
  color: white; /* Text color on hover */
}

.creator-month-tab:focus {
  outline: none;
}

.creator-month-tab.active {
  color: #4add80; /* Updated color for active tab text */
  font-weight: bold;
  border-bottom: 3px solid #4add80; /* Active tab color, adapted from the image */
}

@keyframes month-click-animation {
  0%, 100% {
    background-color: #1E1E1E; /* Original background color */
  }
  50% {
    background-color: rgba(74, 221, 128, 0.1); /* Adjusted color with transparency */
  }
}

.animating {
  animation: month-click-animation 0.5s ease forwards;
}

.creator-year-and-delete-container {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: space-between; /* Distribute space between elements */
  padding: 10px; /* Add padding for spacing */
}


/* Mobile optimization */
@media (max-width: 768px) {
  .creator-calendar-container {
    width: 100%; /* Adjust to 100% to ensure it takes the full width of its parent container */
    max-width: 70vw; /* Use max-width to control the maximum size */
    margin: 10px auto; /* Keep the auto margins to center it horizontally */
  }
  
  .creator-year-and-search-wrapper {
    display: flex;
    flex-direction: column; /* Keep the vertical stacking */
    align-items: center; /* Center-align children */
    width: 100%; /* Use 100% width to make it responsive */
  }
  .creator-year-selector,
  .creator-search-bar-wrapper {
    padding: 5px;
    margin: 5px 0; /* Maintain the vertical spacing */
    width: 100%; /* Ensure these elements take the full width of their parent */
  }

  .creator-search-button {
    padding: 10px 15px;
    font-size: 13px; /* If the font size is still too large, consider reducing it a bit more */
  }
  
  .creator-search-bar-wrapper {
    display: flex;
    justify-content: space-between; /* This ensures the input and button are spaced out */
    max-width: 400px; /* Adjust the max-width to fit your design */
    margin: 0 auto; /* Center the search bar within its parent */
    margin-top: 12px;
  }
  
  .creator-search-input {
    font-size: 12px;
    flex-grow: 1; /* Allows the input to take up available space */
  }
  

  .creator-month-tabs {
    flex-wrap: wrap; /* Allow month tabs to wrap onto multiple lines */
    justify-content: center; /* Center-align tabs for aesthetics */
  }

  .creator-month-tab {
    padding: 8px 10px; /* Reduce padding for more compact tabs */
    font-size: 14px; /* Slightly reduce font size */
  }
}

/* Tablet optimization */
@media (min-width: 769px) and (max-width: 1280px) {
  .creator-calendar-container {
    width: 100%; /* Adjust to 100% to ensure it takes the full width of its parent container */
    max-width: 70vw; /* Use max-width to control the maximum size */
    margin-left: auto; /* Centering the calendar */
    margin-right: auto; /* Centering the calendar */  
    }

  .creator-year-and-search-wrapper {
    gap: 60px;
    
  }

  .creator-search-input {
    font-size: 12px; /* Increase font size for input field */
  }

  .creator-month-tabs {
    justify-content: space-around; /* Distribute month tabs evenly */
  }

  .creator-month-tab {
    padding: 10px 15px; /* Adjust padding for a more comfortable tap target */
    font-size: 16px; /* Adjust font size for readability */
  }
}
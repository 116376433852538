.signup-page {
  text-align: center;
  margin-top: 50px;
  width: 80%;
  margin: 50px auto;
  background-color: #161616; /* Dark background */
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  margin: 0 auto;
   /*border: 1px solid #3a3a3a; /* Dark border */
  padding: 20px;
  border-radius: 8px;
  
}

.signup-page h1 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #FFFFFF;
  font-weight: bold;
}


.signup-page a {
  color: #007BFF;
  text-decoration: underline;
  color: #CCCCCC;
}

.special-text {
  font-size: 13px;
  color: #CCCCCC;
  width: 70%;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.terms-text {
  font-size: 11px;
  color: #CCCCCC;
  width: 70%;
  margin-top: 20px;
  padding: 10px;
  text-align: left;
  margin-bottom: 10px;
  text-decoration: none;
}
.terms-text a {
  text-decoration: none;
  color: inherit; /* To keep the color same as parent */
}

.email-input {
  background-color: #161616; /* Dark background */
  color: #ffffff; /* Light text */
  border: 1px solid #303030; /* Subtle border */
  padding: 10px 15px; /* Adequate padding for comfort */
  border-radius: 4px; /* Slightly rounded corners */
  font-size: 16px; /* Comfortable reading size */
  width: 40%; /* Full width to fit container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 20px; /* Spacing below the input field */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);

}

.email-input::placeholder {
  color: #303030; /* Lighter color for placeholder text */
}

.email-input:focus {
  outline: none; /* Remove outline on focus */
  border-color: #4add80; /* Highlight with a brighter color on focus */
}



 .continue-button {
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #54565c; /* Same as background color */
  color: #ffffff;
  border: 1px solid #54565c; /* White outline */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 16px;
  font-weight: bold;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);

}

.continue-button:hover {
  background-color: #66686e; /* Same as background color */
}

 .google-button .google-logo {
  margin-left: 8px;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  margin-left: 0;
}


.google-button {
  background: none;
  border: none;
  padding: 0;
  margin: 10;
}
.google-logo {
  margin-top: 20px;
  width: 220px !important;
  height: 53px !important;  
}

.google-button img {
  transition: 0.3s ease;
}

.google-button:hover img {
  content: url('../images/googlesignwhite.png');
}






.button-divider {
  position: relative;
  height: 1px;
  background-color: #54565c;
  margin: 16px auto;
  width: 40%;
  
}

.divider-text {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #181818; /* Same as your background color */
  padding: 0 10px;
  color: #9b9da3;
}

.processing-text {
  margin-top: 40px;
  color: white;
}
.processing-image {
  width: 100px;
  height: auto;
}
@keyframes fadeInOut {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.processing-text, .processing-image {
  animation: fadeInOut 2s infinite;
}

.login-text {
  color: #9b9da3; /* Same as your button text color */
  font-size: 14px;
  margin-bottom: 16px;
}

.login-text strong a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}



.password-validation-msg {
  color: #FF5733; /* You can adjust this color */
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 70%;
}

.email-validation-msg {
  color: #FF5733;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
  margin-bottom: 20px;
  width: 70%;
}


.forgot-password-msg {
  color: #FF5733;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: bold;
}

.reset-password-button {
  padding: 12px 24px;
  border-radius: 12px;
  background-color: #54565c; /* Same as background color */
  color: #ffffff;
  border: 1px solid #54565c; /* White outline */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bold;
}

.reset-password-button.email-sent {
  background-color: #3da6fe; /* Green background */
}


.forgot-password {
  color: #CCCCCC; /* Same as your button text color */
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
}



@media (max-width: 768px) {
  .signup-form {
    width: 100%;  /* Take full width on mobile */
    padding: 20px;
    box-sizing: border-box;
  }
  .signup-page {
    margin-top: 10px;
    width: 100%;  /* Take full width on mobile */

  .processing-text {
      font-size: 1.0em;
    }
  }

}

/* HOMEPAGE BLOCKS */
/* HOMEPAGE BLOCKS */
/* HOMEPAGE BLOCKS */
/* HOMEPAGE BLOCKS */


.selling-points {
    margin-top: 150px;
    margin-bottom: 10px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.exclusive-features-title {
    text-align: center;
    font-size: 18px;
    color: #f1f1f1;
    margin-top: 0; /* Remove default margin */
}

.preview-points {
    margin-top: 35px;
    margin-bottom: 50px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}


.preview-point-item {
    width: 200px;
    height: 150px;
    border-radius: 15px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 16px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease;
    border: 1px solid #0e0e0e;
}

.preview-point-item:hover {
    transform: scale(1.05);
}


.selling-point-item {
    width: 200px;
    height: 180px;
    border-radius: 15px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 16px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease;
    border: 1px solid #0e0e0e;
}

.selling-point-item:hover {
    transform: scale(1.05);
}


.selling-point-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.selling-point-description {
    font-size: 14px;
    
}

.point-logo {
    width: 50px;
    height: 50px;
}

/* CREATOR PAGE BLOCKS */
/* CREATOR PAGE BLOCKS */
/* CREATOR PAGE BLOCKS */
/* CREATOR PAGE BLOCKS */


.unauth-preview-points {
    margin-top: 35px;
    margin-bottom: 50px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.unauth-preview-point-item {
    width: 160px;
    height: 115px;
    border-radius: 15px;
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease;
    border: 1px solid #0e0e0e;
}

.unauth-preview-point-item:hover {
    transform: scale(1.05);
}


.unauth-selling-point-title {
    font-size: 20px;
    font-weight: bold;
}


.unauth-point-logo {
    width: 52px;
    height: 52px;
}




@media (max-width: 768px) {
    .selling-points {
        width: 90%;

        margin-top: 40px;
        margin-bottom: 10px;
    }

    .unauth-selling-points {
        width: 90%;

        margin-top: 40px;
        margin-bottom: 10px;
    }

}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .plan-popup-wrapper {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  .outoftime-popup-container {
    background-color: #0e0e0e; /* Dark background similar to YouTube dark theme */
    color: #ffffff;
    border-radius: 5px;
    padding: 24px;
    width: 400px;
    border: 1px solid #3e3e3e;
    /* border: 1px solid #ffffff; */
  }
  
  .close-btn {
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  

  

  .add-more-time-btn {
    background-color: #3da6fe; /* YouTube red */
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 15px 25px;
    cursor: pointer;
    margin-top: 15px;
    font-weight: bold;
    font-size: 1.1em;
   
  }
  
  .add-more-time-btn:hover {
    background-color: #4add80; /* Darker red for hover */
    transform: scale(1.0);
  }
  

.popup-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #ffffff; /* White text */
  }
  
  .popup-subtitle {
    font-size: 16px;
    margin-bottom: 24px;
    color: #ffffff; /* White text */
    
  }
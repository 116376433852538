.pricing-page-container-home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    justify-content: flex-start;
    margin-top: 20px;
     /* Align items to the start of the main axis */
  }
  
  .pricing-container-home {
    display: grid;
    grid-template-columns: 1.2fr 1fr; /* Two equal columns */
    align-items: center;
    max-width: 100%;  /* Limit the maximum width */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -50px;
    margin-top: 50px;

  }

  .pricing-plan-chooser {

      width: 350px;


  }
  
  .proceed-btn-price {
    background-color: #ffffff;
    color: #161616;
    border: 2px solid transparent; /* Prepare for a border color change */
    border: none;
    border-radius: 8px;
    padding: 15px 25px;
    cursor: pointer;
    margin-top: 30px;
    font-weight: bold;
    font-size: 1.0em;
    display: block; /* Ensures the element is block-level */
    margin-left: auto; /* Centers the button horizontally */
    margin-right: auto;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease, border-color 0.3s ease; /* Smooth transitions */

  }

  .proceed-btn-price:hover {
    background-color: #11af82; /* New hover background */
    color: #fff; /* Maintain white text or choose a contrasting color */
    transform: scale(1.05); /* Slight scale */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    border-color: #fff; /* Optional: contrasting border color */
  }

  .essential-info-main {
    background-color: #0e0e0e;
    padding: 20px;
    text-align: left;
    border-radius: 10px;

  }

  .priceLlama{
    height: auto;
    width: 340px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    border-radius: 10px;

  }


  .upload-minutes-textpreview {
    color: #4add80;

  }
 
  

  .pricing-plan-chooser, .priceLlama {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); /* Add shadow */
    transition: transform 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .pricing-plan-chooser:hover, .priceLlama:hover {
    transform: translateY(-5px); /* Slight lift on hover */
  }
  

  @media only screen and (max-width: 768px) {
    .pricing-page-container, .pricing-container {
      width: 90%;  /* Adjust this value as needed */

    }

    .pricing-page-container-home {
      margin-top: 0px;
    }


    .pricing-container-home {
      margin-top: 15px;
      margin-bottom: -20px;

      grid-template-columns: 1fr;
  
    }

    .priceLlama{
        margin-top: 40px;
    }

    
  }
.thumbnail-layout {
  margin-top: 10px;
    padding: 25px;
  }
  
  .thumbnail-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  /* Center-align items horizontally */
    gap: 16px;
    max-width: 1360px;  /* Adjusted to accommodate 4 thumbnails */
    margin: auto;  /* Center the grid */
    
  }
  
  .fade-in {
    opacity: 0.4;
    animation: fadeInEffect 1s forwards;
  }
  
  @keyframes fadeInEffect {
    to { opacity: 1; }
  }
  
  
  
  .thumbnail-item {
    position: relative;
    border-radius: 10px 0 0 0;
    overflow: hidden;
    width: 320px;  /* 1/4 of 1280 pixels */
    height: 180px;  /* 1/4 of 720 pixels */
    margin: auto;  /* Center each thumbnail item */
    
  }
  
  .thumbnail-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  
  .creator-loading-indicator {
    display: flex;
    align-items: center; /* Centers vertically */
    justify-content: center; /* Centers horizontally */
    height: 30vh; /* Adjust as needed */
  }
  
  .loading-content-wrapper {
    display: flex;
    align-items: center; /* Centers vertically */
  }

  .creator-processing-image {
    width: 200px;
    height: auto;
    opacity: .9;
  }
  
  .creator-loading-text {
    color: white;
    font-weight: bold;
    font-size: 18px;
  }
  
/* THUMBNAIL EXTRA DESIGN CSS */
/* THUMBNAIL EXTRA DESIGN CSS */
/* THUMBNAIL EXTRA DESIGN CSS */
/* THUMBNAIL EXTRA DESIGN CSS */

  .duration-badge {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #2b2f3b;
    color: #ffffff;  /* Changed to white */
    font-weight: bold;  /* Added */
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 14px;
    z-index: 1;  /* Added to ensure it's above the thumbnail */
  }

/* TIMESTAMP CSS */
/* TIMESTAMP CSS */
/* TIMESTAMP CSS */
/* TIMESTAMP CSS */

  .timestamp-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #DC143C;
    color: #ffffff;  /* Changed to white */
    font-weight: bold;  /* Added */
    border-radius: 20%;
    padding: 2px;
    font-size: 14px;
    z-index: 1;  /* Added to ensure it's above the thumbnail */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }


  .timestamp-badge.in-queue {
    background-color: #ff8c00;
  }
  
  .timestamp-badge.done {
    background-color: #3cb371 !important;
  }
  
  .timestamp-badge img {
    /* Adjust the width and height as needed */
    width: 30px;  /* Example width */
    height: 30px;  /* Example height */
    /* You might want to add additional styles such as object-fit */
}



  .info-rectangle {
    background-color: #272727;
    border-bottom-left-radius: 10px;  /* Rounded bottom-left corner */
    border-bottom-right-radius: 10px;
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }


  .thumbnail-wrapper:hover .info-rectangle {
    background-color: #333333;  /* Replace with the lighter grey color you prefer */
  }
  

  .videocontent-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between; /* Adjust if needed */
    margin: auto;
    width: 420px; /* Adjust width as needed */
  }
  

  
  .thumbnail-wrapper {
    position: relative;
    overflow: hidden;
    width: 320px; /* Thumbnail width */
    
  }
  
  /* background-color: rgba(85, 255, 110, 0.2); */

  .error-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 85, 85, 0.2);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .error-message-box {
    background-color: #0e0e0e;
    border-radius: 5px;  /* Rounded corners */
    padding: 17px;  /* Internal padding */
    border: 2px solid #3e3e3e;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);

  }
  
  .error-message {
    font-size: 16px;
    font-weight: bold;
    margin: 5px;  /* Adjust the margin as needed */
  }

  .retry-message {
    font-size: 14px;
  }
  
  .retry-message a{
    color: #4add80;
    text-decoration: underline;

  }
  .video-title {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    padding: 5px;
    margin-left: 10px;
    text-align: left;
    font-weight: bold;
  }

  .action-icon {
    color: #9b9ea3;
    font-size: 20px;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  

  /* DROPDOWN MENU CSS */
  /* DROPDOWN MENU CSS */
  /* DROPDOWN MENU CSS */
  /* DROPDOWN MENU CSS */

  .info-action-wrapper {
    z-index: 1;
    position: relative;
    
  }

  
  .action-dropdown {
    position: relative;
    background-color: #181818;
    border-radius: 8px;
    padding: 08px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
    width: 140px;
    left: 150px;
    top: -15px;
    pointer-events: none;
    border: 1px solid #3e3e3e;

  }
  
  .action-dropdown.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }


  .dropdown-item {
    background-color: #181818;
    color: #fff;
    border: none;
    text-align: left;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    transition: background-color 0.2s ease;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .process-dropdown-item {
    background-color: #181818;
    color: #fff;
    border: none;
    text-align: left;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    transition: background-color 0.2s ease;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: -5px;
  }

  .active-process-dropdown-item {
    background-color: #181818;
    color: #fff;
    border: none;
    text-align: left;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    transition: background-color 0.2s ease;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: 5px;
    opacity: .7;
  }



.dropdown-item:hover {
  color: #4add80;
}


.dropdown-item--no-highlight:focus, .dropdown-item--no-highlight:hover {
  outline: none;
  box-shadow: none;
}





.creator-iconlayout {
  width: 35px;
  height: 35px;
  margin-right: 0px;
  vertical-align: middle;
}


/* In your .css file */
.action-dropdown {
  pointer-events: none;
  /* ...other styles... */
}

.action-dropdown.active {
  pointer-events: auto;
  /* ...other styles... */
}

  .youtube-logoicons {
    width: 35px;
    height: 35px;
    vertical-align: middle;

  }

/* New CSS for the side panel */
.video-stats-container {
  display: flex;
  align-items: center;
  width: 100px; /* Adjust the width as needed */
}

.video-stats {
  background-color: #181818;
  border-radius: 0px 8px 8px 0px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #3e3e3e;
  border-top: 1px solid #3e3e3e;
  border-right: 1px solid #3e3e3e;
}
.video-stats.dimmed {
  opacity: 0.5; /* Adjust the value as needed */
}


.video-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-box {
  background-color: #2b2f3b;
  color: #ffffff;
  font-weight: bold;
  border-radius: 12px;
  padding: 5px 10px;
  font-size: 16px;
}

.stat-icon {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  margin-top: 4px;
}

.stat-icon-top {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
}

.stat-label {
  display: none; /* Hide the original labels */
}

/* Guideance TEXT */
/* Guideance TEXT */
/* Guideance TEXT */
/* Guideance TEXT */


.guidance-text {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 80px;
  font-size: 16px;
  color: #cfd0d0;
  opacity: 0; /* Start fully transparent */
  animation: fadeIn 5s ease forwards; /* 3s fade in, 'forwards' keeps the final state */
}

.guidance-text a {
  color: #4add80;
  text-decoration: underline;
}


.guidance-text-unauth {
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
  color: #cfd0d0;
}

.guidance-text-unauth a {
  color: #4add80;
  text-decoration: underline;
}

@keyframes fadeIn {
  to { opacity: 1; } /* Fade to fully visible */
}


/* No Vids For Month */
/* No Vids For Month */
/* No Vids For Month */
/* No Vids For Month */



.no-videos-container {
  display: flex;
  align-items: center; /* Vertically center the items inside the container */
  justify-content: center; /* Horizontally center the items inside the container */
  gap: 20px; /* Add some space between the image and the text */
  width: 100%; /* Take up the full width of the parent */
  flex-direction: column; /* Stack items vertically */
  height: 20vh; /* Adjust as needed */


}

.no-videos-image {
  width: 60px; /* Adjust the size of the image as needed */
  height: auto;
}
.no-videos-message {
color: white;
font-weight: bold;
}


/* Grab Catalog Button */
/* Grab Catalog Button */
/* Grab Catalog Button */
/* Grab Catalog Button */


.needcatalogwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh; /* Adjust as needed */
}

.fetchCatalogButton {
  background-color: #161616; /* Green */
  border: none;
  color: #ffffff;
  padding: 20px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-flex; /* Use flexbox to align image and text */
  align-items: center; /* Vertically center image and text */
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  border: 1px solid #3e3e3e;
  transition: transform 0.3s ease; /* Add transition for smooth effect */

}

.fetchCatalogButton:hover {
  transform: scale(1.05);

}


.buttonImage {
  width: 40px;
  height: auto;
  margin-right: 10px; /* Adjust spacing between image and text */
}


    /* Creaor- NO ACCESS-Layout */
    /* Creaor- NO ACCESS-Layout */
    /* Creaor- NO ACCESS-Layout */
    /* Creaor- NO ACCESS-Layout */

    .creator-noaccess-preview {
      display: flex;
      align-items: center;
      justify-content: center; /* Center the new wrapper */
      margin-top: 30px;
    }

    .content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around; /* Adjust the space between the button and image */
      gap: 30px;
    }
    

    .creator-noaccess-button {
        background-color: #54565c;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        font-size: 20px;
        padding: 20px 20px;
        color: white;
        cursor: pointer;
        display: flex; /* To align the image and text inline */
        align-items: center; /* Center the items vertically */
        justify-content: center; /* Center the items horizontally */
        }

        .creator-noaccess-button:hover {
          transform: scale(1.05); /* Slightly larger on hover */

        }
    
        .creator-noaccess-image img {
          border-radius: 15px;
          max-width: 380px;
          width: 100%;
          position: relative;
          overflow: hidden;
          transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition for opacity and transform */
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

    






    /* UNAUTH-Layout */
    /* UNAUTH-Layout */
    /* UNAUTH-Layout */
    /* UNAUTH-Layout */
    /* UNAUTH-Layout */

    .unauth-thumbnail-layout {
      padding: 20px;
      margin-top: 10px;
    }

    .tabs-container {
      display: flex;
      justify-content: flex-start;
      padding: 20px; /* Adjust padding as needed */
      width: 100%; /* Ensure the container takes full width */
      margin-bottom: 20px;
    }
    
    .tab {
      padding: 10px 20px; /* Padding around the text */
      margin: 0px; /* Margin between tabs */
      cursor: pointer; /* Changes cursor to pointer on hover */
      border-radius: 5px; /* Rounded corners */
      transition: background-color 0.3s ease; /* Smooth transition for background color */
      /* More styling can be added here depending on your design requirements */
    }
    
    .tab.active {
      color: #fff; /* Active tab text color */
      border: 1px solid #3e3e3e;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    }
    
    .unauth-exit-button {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px;
      background: none;
      border: none;
      font-size: 16px;
      cursor: pointer;
      color: white;
    }

/* Button base styles */
.reload-button-unauth {
  background-color: #161616; /* Same as .search-button */
  border: none;
  color: #ffffff;
  padding: 7px 7px; /* Adjust padding as needed to accommodate the image */
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Add background-color to the transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border: 1px solid rgba(62, 62, 62, 1.0); /* Border with 0.5 opacity */
}

.reload-button-unauth img {
  width: 25px;
  height: 25px;
  object-fit: contain; /* This ensures the image maintains its aspect ratio without being cropped. */
}

/* Hover styles */
.reload-button-unauth:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  background-color: rgba(74, 221, 128, 0.2) !important; /* Slight green with transparency, added !important */
}

/* Tooltip styles */
.reload-button-unauth:hover::after {
  content: attr(data-hover-text);
  position: absolute;
  top: -30px; /* Adjust this value to position the tooltip above the button */
  left: 0%; /* Align the tooltip to the left side of the button */
  transform: translateX(0%); /* No horizontal offset */
  background-color: #fff; /* Background of the tooltip */
  color: #000; /* Text color of the tooltip */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 10; /* Ensure it's above other items */
  opacity: 0.7;
  pointer-events: none; /* Prevent tooltip from being interactive */
  box-shadow: 0px 2px 10px rgba(0,0,0,0.1);  /* Optional: add shadow for better visibility */
}

  
  .stat-box {
    margin-top: 4px;
    position: relative;
    font-size: 1.1em; /* Adjust main number size as needed */
    display: inline-block;
  }
  
  .stat-box.number {
    color: #4add80; /* Green color */
  }
  
  .stat-box.default {
    color: #ffffff; /* White color */
  }

  .stat-box .increase {
    position: absolute;
    top: -10px; /* Adjust vertical position as needed */
    right: -7px; /* Adjust horizontal position as needed */
    font-size: 0.6em; /* Adjust increase number size as needed */
    color: #4add80; /* Adjust text color as needed */
    background-color: #1e1e1e; /* Adjust background color as needed */
    padding: 2px 5px; /* Adjust padding as needed */
    border-radius: 5px; /* Adjust border radius as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow as needed */
  }

  @media (max-width: 768px) {

    .thumbnail-grid {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 0;
      margin: 0 auto;
    }
    .thumbnail-layout {
        margin-right: 10px;
        width: 90%;
      }
    .calendar-search-wrapper {
      flex-direction: column;
    }
    .unauth-thumbnail-layout {
      margin-top: 30px;
        
    }
    .videocontent-container {
      max-width: 100%;
    }

    .videocontent-container {
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .video-stats-container {
      display: none; /* Hide the video stats container */
    }
    

    .tabs-container {
      display: flex;
      justify-content: start; /* Align items to the start of the container */
      padding: 5px; /* Adjust padding as needed */
      width: 80%; /* Ensure the container takes full width */
    }


    .creator-settings-container {
      margin-top: -40px;

    }

    .creator-noaccess-preview {
      margin-top: 30px; /* Reduce margin for smaller screens */
    }
  
    .content-wrapper {
      flex-direction: column; /* Stack image and button vertically */
      gap: 20px; /* Reduce gap for a more compact layout */
      padding: 10px; /* Add some padding for breathing space */
    }
  
    .creator-noaccess-image img {
      max-width: 280px; /* Adjust image size for smaller screens */
      transition: opacity 0.3s ease, transform 0.3s ease; /* Ensure smooth transitions */
    }
  
    .creator-noaccess-button {
      font-size: 16px; /* Reduce font size for smaller screens */
      padding: 12px 12px; /* Adjust padding for a more compact button */
    }
    .guidance-text{
      font-size: 13px; /* Reduce font size for readability */
    }
    .guidance-text-unauth {
      font-size: 13px; /* Reduce font size for readability */
      text-align: center; /* Center-align text for a better layout */
      margin-bottom: 40px; /* Add some margin for separation from other elements */
    }
  }


  /* Tablet optimization */
@media (min-width: 769px) and (max-width: 1280px) {
  .creator-noaccess-preview {
    margin-top: 40px; /* Slightly more margin than on mobile */
  }

  .content-wrapper {
    flex-direction: row; /* Maintain horizontal layout but adjust spacing */
    gap: 25px; /* Adjust gap for a more balanced layout */
  }

  .creator-noaccess-image img {
    max-width: 300px; /* Adjust image size for larger screens */
  }

  .creator-noaccess-button {
    font-size: 17px; /* Slightly larger font size than on mobile */
    padding: 14px 14px; /* Slightly larger padding for a more tactile button */
  }

  .guidance-text-unauth {
    font-size: 16px; /* Adjust font size for better readability */
    text-align: left; /* Align text to the left for a more natural flow */
    margin: 25px 0; /* Adjust margin for better spacing */
  }
}
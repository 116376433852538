/* Aboutus.css */

.aboutus-container {
    background-color: #161616;
    color: #CCCCCC;
    font-family: 'Arial', sans-serif; /* Use a modern font-family */
    line-height: 1.6;
    width:100%
    
  }
  
  .aboutus-header {
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center;
    padding: 10px 20px 30px 20px;
    max-width: 60%;
    margin: 0 auto;
  }
  
  .aboutus-header h1 {
    width: 50%;
    font-size: 3.8em;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  
  .aboutus-thumbnail {
    max-width: 100%;
    height: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 1px solid #3e3e3e;

  }
  
  .aboutus-content {
    max-width: 50%;
    margin: 0 auto;
    padding: 20px;
    text-align: left; /* Ensures all text within aboutus-content is left-aligned */
  }
  
  .aboutus-section {
    margin-bottom: 40px;
  }
  
  .aboutus-section h2 {
    color: #ffffff; /* Or any other accent color you prefer */
    font-size: 2em;
    margin-bottom: 15px;
  }
  
  .aboutus-section p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }






  .pill {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    background-color: #333;
    border-radius: 50px; /* Adjust as necessary for pill shape */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 1px; /* Space before the section starts */
  }
  
  .pill-icon {
    width: 34px; /* Adjust size as needed */
    height: 34px;
    margin-right: 5px; /* Space between icon and text */
  }
  
  .pill-text {
    color: #FFF;
    font-weight: bold;
  }

  
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .aboutus-header {
        padding: 15px 10px;
        max-width: 90%;
    }
    
    .aboutus-header h1 {
        margin-top: 30px;
        width: 70%;
        font-size: 1.6em;
    }
    
    .aboutus-content {
        max-width: 90%;
        padding: 15px;
    }
    
    .aboutus-section h2 {
        font-size: 1.5em;
    }
    
    .aboutus-section p {
        font-size: 1em;
    }
    
    .pill {
        padding: 6px 12px;
    }
    
    .pill-icon {
        width: 24px; /* Adjust size as needed */
        height: 24px;
    }
    
    .pill-text {
        font-size: 0.9em; /* Adjust text size as needed */
    }
  }
  
/* General Styles */
.brand-template-container {
    color: #FFFFFF;
    background-color: #2c2c2c;
    padding: 22px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;

  }
  
  .brand-template-container h1 {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }
  
  /* Input and Label Styles */
  .input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
    max-width: 400px;
    margin: 0 auto;
    
    
  }
  
  .input-container label {
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .input-container input,
  .input-container textarea {
    padding: 12px;
    font-size: 1.1rem;
    margin-bottom: 20px;
    border: 2px solid #3a3a3a;
    border-radius: 4px;
    width: 100%;
    background-color: #0e0e0e;
    color: #fff;
    resize: none; /* Prevents resizing */
    outline: none; 
  }

  .input-container input:hover,
.input-container textarea:hover {
  border-color: white;
}


.char-count {
  position: absolute;
  bottom: 30px;
  right: -17px;
  font-size: 0.8rem;
  color: #ccc;
}


.subtitle {

text-align: left;
}









  /* Button Styles */
  .brand-template-container button {
    padding: 12px 24px;
    font-size: 1.1rem;
    background-color: #303030;
    color: #FFFFFF;
    border: 2px solid #3a3a3a;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .brand-template-container button:hover {
    background-color: #3a3a3a;
  }
  
  .error-text {
    color: #FF5555;
    font-size: 0.9rem;
    margin-top: 5px;  /* Increased spacing */
    margin-bottom: 20px;  /* Increased spacing */
  }




  .advanced-feature {
    position: relative;
  }
  
  .advanced-label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #3da6fe;
    font-weight: bold;
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }
  

  .divider {
    height: 0px;
    background-color: #2c2c2c;
    margin: 20px auto;  /* Center the divider */
    width: 50%;
  }



/* PREVIEW COMMENT CSS */

.preview-text {
    background-color: #0e0e0e;
    border: 1px solid #3e3e3e;
    border-radius: 12px;
    padding: 19px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-align: left;
  }


  .preview-section {
  margin-top: 40px; 
  margin-bottom: 25px; 
}


  .prevprofile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    border:2px solid #0e0e0e;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }
  

.preview-container {
    margin-top: 30px;
}

.preview-comment {
    display: flex;
    align-items: flex-start;
    justify-content: center; /* Center the comment horizontally */
    
}

.preview-profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.preview-content {
    flex: 1;
    white-space: pre-line; /* This will respect new lines and spaces in your text */
}

.timestamp-time {
    font-weight: bold;
    color: #3da6fe;
    margin-right: 2px;
  }


  .essential-label {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -20px;
    background-color: #0e0e0e;
    color: #4add80;
    padding: 4px 5px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
  




.input-container.essential-feature {
  display: flex;
  flex-direction: row;
  align-items: center; /* This will vertically center align the children */
  padding: 5px; /* Adjust as needed */

}


.nobranding-label {
  background-color: #0e0e0e;
  align-items: center;
  color: #4add80;
  padding: 4px 5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none; /* Remove underline from links */
  white-space: nowrap; /* Keep text on a single line */
}

  .checkbox-wrapper {
    display: flex;
    gap: 5px;
    flex-direction: row;
    align-items: center;
    width: 100%;    
  }
  
  .remove-branding-label {
    font-size: 16px;  /* Makes the text small */
    color: #ffffff;  /* Makes the text white */
    font-weight: bold;  /* Makes the text bold */
    white-space: nowrap;
    align-items: center;
    margin-left: 20px;
  }
  
  .styled-checkbox {
    width: 20px;
    height: 20px;
    margin-top: 20px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    background-color: #333;
    cursor: pointer;
  }
  
  .styled-checkbox:checked {
    background-color: #3da6fe;
    border: 2px solid #333;
    background-repeat: no-repeat;
    background-position: center;
  }

  .custom-dropdown {

    outline: none; 
  }


  .savetemp-button {
    /* Your existing styles */
    background-color: #161616 !important;
    padding: 15px 20px !important;
    border: none;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, background-color 2s ease; /* Adjusted background-color transition */
  }
  
  .savetemp-button:hover {
    background-color: #45c072 !important; /* Color to change to on hover */
  }
  
  .savetemp-button-saved {
    font-weight: bold;
    background-color: #45c072 !important;
    animation: saveEffect 0.4s ease-in-out forwards; /* Trigger animation on saved state */
  }
  
  @keyframes saveEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1); /* Scale up */
    }
    100% {
      transform: scale(1); /* Return to original size */
    }
  }
  
  
  
  /* Mobile Responsiveness */
@media only screen and (max-width: 768px) {
  .brand-template-container {
    padding: 12px;
    text-align: center; 
  }
  .input-container {
    max-width: 85%;
    margin: 0 10px;
  }
  .subtitle {
    text-align: left;
  }
  .preview-comment {
    flex-direction: column;
    align-items: center;
  }
  .brand-template-container h1 {
    font-size: 2rem;
  }
  .prevprofile-image {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  .remove-branding-label {
    text-align: center;
  }
  .checkbox-wrapper {
    align-items: center; 
    flex-direction: column;

  }
  .styled-checkbox {
    margin-top: 10px;
  }

  .center-checkbox {
    justify-content: center;
  }
  .nobranding-label {
    display: none;
  }
  .preview-section {
    margin-right: 15px; 
  }



}


.meta-settings {
    display: flex;
    background-color: #161616;
    min-height: 100vh;
}




.settings-container {
    display: flex;
    background-color: #2c2c2c;
    color: white;
    width: 100%; /* Use 100% width for full responsiveness */
    height: 100%;
    max-width: 768px;
    margin: 3% auto;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0.5% 1% rgba(0, 0, 0, 0.1);
    border: 1px solid #3e3e3e;

}

.settings-sidebar {
    width: 25%; /* Adjust if necessary for smaller screens */
    background-color: #222;
    padding: 2%;
}

.sidebar-item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.sidebar-item.active {
    background-color: #555;
}

.settings-content {
    flex: 1; /* Allows content to fill the remaining space */
    padding: 2%;
    overflow-y: auto;
}

.sidebar-icon {
    margin-right: 6px;
    width: 30px;
    height: 30px;
}

/* Profile and User details layout */
.general-content {
    display: flex;
    flex-direction: row; /* Align children horizontally */
    align-items: center; /* Align children vertically in the middle */
    gap: 10px; /* Spacing between children */
}

.profile-section {
    flex: 0 0 auto;
    min-width: 170px; /* Minimum width before wrapping */
    position: relative;
    padding-right: 5px; /* Decreased padding to reduce space */
    margin-left: 20px;
}

.profile-image-container {
    position: relative;
    width: 170px; /* This should be the same as the profile image for proper alignment */
    height: 170px;
}

.profile-image {
    display: block;
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
    border-radius: 10%;
    object-fit: cover;
}

.update-button {
    position: absolute;
    bottom: 10px; /* Position from the bottom of the profile image container */
    right: 0px; /* Position from the right of the profile image container */
    background-color: transparent;
    border: none;
    /* If the button has content, make sure it doesn't overflow the image container */
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease; /* Smooth transition for transform effects */
}

.update-button:hover {
    transform: scale(1.05); /* Increase size by 10% */
}

.button-image {
    display: block;
    width: 20px; /* Width of the button image */
    height: 20px; /* Height of the button image */
    border-radius: 7px;
    /* If you need to center the image within the button or have specific alignment, add here */
}


.user-details {
    flex: 1;
    min-width: 250px; /* Minimum width before wrapping */
}


.email-text {
    font-size: 100%;
    font-weight: bold;
    color: #ffffff;
}

.email-section {
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.plan-container {
    display: flex;
    align-items: center; /* Center align vertically */
    justify-content: center; /* Center align horizontally */
    gap: 10px; /* Space between text and button */
}

.plan-text {
    display: flex;
    align-items: center; /* Aligns the image and text vertically */
    gap: 2px; /* Adds some space between the image and text */
    font-weight: bold;
    margin-right: 5px;
  }
  
  .plan-icon {
    width: 25px; /* Example size, adjust as needed */
    height: 25px; /* Example size, adjust as needed */
  }

  .plan-button {
    display: inline;
    background-color: transparent;
    padding: 0;
    color: white;
    border: none;
    font-size: 1.0rem;
    cursor: pointer;
    margin-right: 5px;
    transition: color 0.3s ease;
    color: #4add80;
    text-decoration: underline;
}


    /* CREATOR SECTION */
    /* CREATOR SECTION */
    /* CREATOR SECTION */
    
    .settings-action {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start; /* Align items to the start */
        padding: 15px; 
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(63, 65, 67); 
      }
      
      .settings-action:last-child {
        border-bottom: none;
      }
      
      .icon-text-container {
        display: flex;
        align-items: center;
        margin-right: auto; /* Pushes all elements to the right */
      }
      
      .action-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      
      .settings-utube-connect-button {
        background-color: #53565b; /* Neutral color suitable for 'connect' action */
        padding: 12px 15px;
        color: white;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.0rem;
        cursor: pointer;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
      }
      
      .settings-utube-connect-button.connected {
        cursor: default;
        background-color: #ffffff; /* Dark gray background */
    } 

      .settings-utube-connect-button:hover {
 
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);

        }
        .settings-create-connectyoutube {
          width: 32px;
          height: 23px;
        }



      .manage-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #181818; /* Dark color for a neutral look */
        padding: 10px 10px;
        color: white;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.0rem;
        cursor: pointer;
        margin-right: 5px; /* Adjust the spacing as needed */
        transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover and shadow */
        width: 40px;
        height: 40px;
      }
      
      .manage-button img {
        max-width: 30px;
        max-height: 30px;
    }
    

      .manage-button:hover {
        background-color: #272727; /* Slightly lighter for hover effect */
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* More pronounced shadow on hover */
      }
      
      .manage-button:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); /* Soft white ring for focus */
      }
      
      .manage-button:active {
        background-color: #0f0f0f; /* Even darker for a pressed effect */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Revert to subtle shadow */
      }
      

      

      /* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 52px;  /* Adjust width as needed */
    height: 26px;  /* Adjust height as needed */
    margin-left: 10px;
    
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ccc; /* Slider off color */
    transition: .4s;
    border-radius: 26px; /* Circular edges */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;  /* Adjust knob size as needed */
    width: 22px;  /* Adjust knob size as needed */
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;  /* Circular knob */
  }
  
  input:checked + .slider {
    background-color: #4add80; /* Slider on color */
  }
  
  input:checked + .slider:before {
    transform: translateX(26px); /* Adjust translation based on the size of the switch */
  }


  
  .disabled-section {
    opacity: 0.5; /* Grey out the section */
}

.disabled-section > *:not(.switch) {
    pointer-events: none; /* Disable interactions for all children except switch */
}

  
  .slider-tooltip-container {
    position: relative;
    display: inline-block;
}

.slider-tooltip-text {
    visibility: hidden;
    width: 230px;
    background-color: #181818;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    transform: translateX(-80%);
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: bold;
    font-size: .9rem;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);

}

.tooltip-icon {
    width: 20px;  /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
    vertical-align: middle; /* Aligns the icon with the text */
}

.switch:hover .slider-tooltip-text {
    visibility: visible;
    opacity: 1;
}
  
.connect-text-utube {
    font-weight: bold;
    font-size: 15px;
  }
.connected-button {
    background-color: #40cc74;
}

.connected-button:hover {
    background-color: #278047; /* Slightly darker than #53565b */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  }

  .revoke-button {
    background-color: #181818;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .revoke-button img {
    height: 20px; /* Adjust size as needed */
    width: auto;
  }
  
  .revoke-button:hover {
    background-color: #272727; /* Slightly lighter for hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
  }
  
  .revoke-button:active {
    background-color: #0f0f0f; /* Even darker for active effect */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.); /* Subtle shadow for active state */
  }

.manage-dropdown {
    padding: 15px 15px; /* Top and bottom padding of 15px, left and right padding of 20px */
    background-color: #272727; /* Or any other color you prefer */
    color: #fff; /* Adjust the text color for better contrast */
    border-radius: 10px; /* Rounded corners for the dropdown */
    width: auto; /* Adjust the width as needed, auto means it will resize with the content */
    display: inline-block; /* To keep the dropdown in line with other inline elements, if necessary */
    outline: none; 
    /* Add other properties as needed */
}


    /* TIMESTAMP SECTION */
    /* TIMESTAMP SECTION */
    /* TIMESTAMP SECTION */

    .timestamp-length-buttons {
      display: flex;
      align-items: center;
      background-color: #2c2c2c; /* Light background to contrast the buttons */
      padding: 5px; /* Padding around buttons for spacing */
      border-radius: 12px; /* Rounded corners for the container */
      box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    }
    
    .timestamp-button {
      padding: 12px 20px; /* Comfortable padding */
      font-size: 0.95em; /* Optimal font size for readability */
      font-weight: 600; /* Bold font for clarity */
      border: none; /* Remove borders */
      background-color: #272727; /* Neutral button color */
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s; /* Smooth transitions for interactive feedback */
      margin: 0 2px; /* Spacing between buttons */
      border-radius: 8px; /* Rounded buttons */
      outline: none; /* Remove focus outline */
      color: #333; /* Dark text for contrast */
      opacity: 0.5;
      color: white;
    }
    
    .timestamp-button:hover {
      background-color: #d1d1d1; /* Slightly darker on hover */
      transform: translateY(-2px); /* Subtle lift effect on hover */
    }
    
    .timestamp-button.selected {
      background: #46d179; /* Gradient based on base color #0ca37e */
      color: #ffffff; /* White text for contrast */
      box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Shadow for depth, indicating selection */
      opacity: 1;
    }
    
    
    
    .info-icon {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      transition: transform 0.2s; /* Animation effect */
      margin-left: 5px;
    }
    
    .info-icon:hover {
      transform: scale(1.1); /* Increase the size by 10% on hover */
    }


/* Mobile Responsiveness */
@media only screen and (max-width: 768px) {

    .meta-settings {
        display: flex;
        flex-direction: row;
      }
    
      .settings-container {
        width: 80%;
        margin: 30px auto; 
        flex-direction: column;

      }
    
      .settings-sidebar {
        width: 100%;
        padding: 5%;
      }
    
      .sidebar-item {
        padding: 8px;
      }
    
      .settings-content {
        width: 100%;
        padding: 5%;
      }
    
      .sidebar-icon {
        width: 25px;
        height: 25px;
      }
    
      .general-content {
        flex-direction: column;
      }
    
      .profile-section {
        min-width: auto;
        margin: 0 auto 5px;
      }
    
      .profile-image-container {
        width: 140px;
        height: 140px;
      }
    
      .profile-image {
        border-radius: 50%;
      }
    
      .update-button {
        width: 25px;
        height: 25px;
      }
    
      .button-image {
        width: 15px;
        height: 15px;
      }
    
      .user-details {
        width: 100%;
        margin-top: 1px;
      }

      .plan-container {

        padding: 0px 30px 5px 10px;

      }
    
      .email-text,
      .connect-text-utube,
      .plan-text,
      .action-icon,
      .connect-button,
      .manage-button,
      .slider-label {
        font-size: 14px;
      }
    
      .email-section,
      .settings-action,
      .manage-dropdown {
        padding: 10px 30px 10px 10px;
      }
    
      
      .plan-icon {
        width: 20px;
        height: 20px;
      }
    
      .plan-button{
        font-size: 0.8rem;



      }

      .connect-button,
      .manage-button,
      .revoke-button {
        padding: 10px 15px;
        font-size: 0.7rem;
      }
    
      .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px; /* Adjusted for better visual balance */
        margin: 0; /* Ensure there's no default margin */
        vertical-align: middle; /* Aligns the switch in line with adjacent text/elements */
        border-radius: 12px; /* Half the height to create a perfect pill shape */
        background-color: #ccc; /* Default background */
        transition: background-color 0.3s; /* Smooth transition for color change */
      }
      
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc; /* Default background */
        transition: .4s; /* Smoother transition for the slider */
        border-radius: 12px; /* Rounded edges */
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 20px; /* Slightly smaller than the switch height for margin */
        width: 20px; /* Square shape */
        left: 2px; /* Small margin from the left */
        bottom: 2px; /* Small margin from the bottom */
        background-color: white;
        transition: transform 0.3s; /* Smooth transition for the sliding action */
        border-radius: 50%; /* Circle shape */
        box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Optional: Shadow for depth */
      }
      
      
      input:checked + .slider:before {
        transform: translateX(16px); /* Move the circle the right */
      }
      
      .slider-tooltip-text {
        display: inline-block;
        margin-left: -5px; /* Space after the switch */
        font-size: 0.8rem;
        padding: 5px;
        background: rgba(0,0,0,0.5); /* Semi-transparent background */
        color: white; /* Text color */
        border-radius: 4px; /* Rounded corners */
        white-space: nowrap; /* Prevents text from wrapping */
        transition: opacity 0.3s; /* Fade effect for tooltip text */
        opacity: 0; /* Hidden by default */
        position: absolute;
        z-index: 2; /* Ensure it's above other elements */
      }
      
      .switch:hover .slider-tooltip-text {
        opacity: 1; /* Show tooltip text when hovered */
        transition-delay: 0.5s; /* Delay to prevent flickering */
      }
      .tooltip-icon {
        width: 15px;
        height: 15px;
      }
    
  
      .settings-utube-connect-button,
      .settings-utube-connect-button.connected 
      
      {
        display: none;


      }

        
      .timestamp-length-buttons {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
        align-items: center; /* Center-align buttons */
      }
    
      .timestamp-button {
        width: 100%; /* Increase button width to take up more space */
        margin: 10px 0; /* Adjust margin for vertical stacking */
      }
      .action-text {
        
        font-size: 14px;
      }


}

.landing-homepage-container {
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    align-items: center;
    max-width: 60%;
    margin: 100px auto 0 auto;
}

.landing-homepage-text {
    text-align: left;
    justify-self: end;
    max-width: 550px;
    margin: 0 auto;
}

.landing-homepage-image {
    justify-self: start;
}

.landing-homepage-image img {
    max-width: 90%;
    height: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6);
    border-radius: 25px;
}

.landing-top-line {
    font-size: 0.9em;
    color: #13c491;
    margin-bottom: 20px;
    text-align: left;
}

.landing-homepage-container h1 {
    font-size: 3.1em;
    color: #FFFFFF;
    margin-bottom: 30px;
    font-weight: bold;
}


.landing-homepage-container p {
    font-size: 1.0em;
    color: #CCCCCC;
    max-width: 95%;
}

.announcement-title {
    font-size: 2.5em;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
    margin-top: 20px;
}

.announcement-subtitle {
    font-size: 1.6em;
    color: #ffffff;
    text-align: left;
}

.highlight-ups {
    color: #13c491;
}

.landing-CTA-button-home {
    padding: 15px 20px;
    font-size: 1.2em;
    background-color: #0e0e0e;
    color: #FFF;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 0.5px solid #3e3e3e;
}

.landing-CTA-button-home:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.landing-helpcenter-button {
    padding: 15px 20px;
    font-size: 1.2em;
    background-color: #fff;
    color: #161616;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.landing-helpcenter-button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.landing-button-icon {
    margin-left: 8px;
    width: 25px;
    height: auto;
}

.landing-powered-by-container {
    display: flex;
    align-items: center;
}

.landing-powered-by-text {
    margin-right: 8px;
    line-height: 1.5;
}

.landing-emphasis {
    color: #FFF;
    font-weight: bold;
}

.landing-openai-logo {
    width: 80px;
    height: auto;
    margin-left: 0.4em;
    vertical-align: middle;
}

.landing-bumpups-logo {
    width: 70px;
    height: 70px;
    vertical-align: middle;
    border-radius: 8px;
    margin-bottom: 5px;
}

/* FAQ Section */
.landing-faq-section {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
}

.landing-faq-section h1 {
    color: white;
    margin-bottom: 30px;
    font-size: 2.5em;
}

.landing-faq-item {
    width: 80%;
    background-color: #0e0e0e;
    margin-bottom: 10px;
    border-radius: 30px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    padding: 12px;
    border: 0.5px solid #3e3e3e;

}

.landing-faq-item h3 {
    margin: 0;
    padding: 15px;
    text-align: left;
    font-weight: bold;
    color: white;
    cursor: pointer;
    border-radius: 30px;
}

.landing-faq-item h3:hover {
    background-color: #121111;
}

.landing-faq-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    background-color: #0e0e0e;
    color: white;
    padding: 0 15px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: left;
    font-size: 1.2em;
}

.landing-faq-content.show {
    max-height: 200px;
    padding: 15px;
}

/* VALUE EXAMPLE */

.landing-valueExamples {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px;
    margin-top: 100px;
    background: #0e0e0e;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.landing-valueExamples-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0e0e0e;
    padding: 15px;
    margin-bottom: 10px;
}

.landing-valueExamples-image img {
    max-width: 60%;
    height: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: -80px;
    border-radius: 15px;
    transition: transform 0.3s ease;
}

.landing-valueExamples-image img:hover {
    transform: scale(1.05);
}

.landing-seamless-integration-title {
    color: white;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
}

.landing-seamless-integration-text {
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    width: 100%;
    text-align: left;
}

.landing-youtube-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.landing-youtube-logohomepage {
    height: 100px;
    width: 100px;
    margin-top: 15px;
}




/* Mobile optimization */
@media (max-width: 768px) {
    .landing-homepage-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .landing-homepage-text {
        font-size: 20px;
        text-align: center;
        max-width: 100%;
    }

    .landing-powered-by-text {
        margin-top: -5px;
        font-size: 14px !important;
    }

    .landing-top-line {
        font-size: 14px;
        text-align: center;
        max-width: 100%;
    }

    .landing-homepage-image {
        margin-top: 20px;
        max-width: 100%;
        min-width: 320px;
        height: auto;
    }

    .landing-homepage-container h1 {
        font-size: 1.5em;
    }

    .landing-faq-section {
        width: 90%;
    }

    .landing-CTA-button-home {
        font-size: 16px;
        padding: 10px 15px;
        margin: 10px auto;
    }

    .landing-CTA-button-home:hover {
        transform: scale(1.05);
    }

    .landing-valueExamples {
        flex-direction: column;
        margin-top: 80px;
        margin-bottom: 100px;
    }

    .landing-valueExamples-text {
        padding: 10px;
        margin-bottom: 0px;
    }

    .landing-valueExamples-image img {
        max-width: 80%;
        border-radius: 14px;
        margin-bottom: -50px;
    }

    .landing-seamless-integration-title {
        font-size: 24px;
    }

    .landing-youtube-logohomepage {
        height: 80px;
        width: 80px;
    }

    .announcement-title {
        text-align: center;
    }
}



/* Tablet optimization */
@media (min-width: 769px) and (max-width: 1280px) {
    .landing-homepage-container {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .landing-homepage-text, .landing-top-line {
        text-align: center;
        max-width: 100%;
    }

    .landing-homepage-image {
        margin-top: 40px;
    }

    .landing-homepage-container h1 {
        font-size: 3.0em;
    }

    .landing-homepage-container p {
        font-size: 0.9em;
    }

    .landing-CTA-button-home {
        font-size: 1.1em;
        margin-left: auto;
        margin-right: auto;
    }

    .landing-helpcenter-button {
        font-size: 1.1em;
        margin-left: auto;
        margin-right: auto;
    }

    .landing-valueExamples {
        flex-direction: column;
        margin-top: 80px;
        margin-bottom: 100px;
    }

    .landing-faq-section {
        width: 70%;
    }

    .announcement-title {
        text-align: center;
    }
    


}

.terms-container {
    padding: 20px;
    font-size: 16px;
    color: white;
  }
  .terms-title {
    font-size: 56px;
    color: white;
    margin-bottom: 20px;
  }
  
  .terms-text-container {
    max-width: 700px;
    margin: auto;
    text-align: left;
    color: #cccccc;
    padding-top: 30px;
  }

  .terms-text-container h3 {
    font-size: 24px;
    color: #ffffff;  /* Gold color for emphasis */
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .policyLinks {
    text-decoration: none;
    font-weight: bold;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    .terms-container {
      padding-left: 20px;  
      padding-right: 20px;  
    }
  }
  
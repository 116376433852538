
.uploaddashboard {
  display: flex;
  background-color: #161616;
  min-height: 100vh;

}


.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}



  .urlinput-section {

      width: 100%;


  }
.urlinput-container {
  display: flex;
  align-items: center;
  background-color: #2C2C2E; /* Dark background */
  padding: 15px 15px;
  border-radius: 10px;
  max-width: 650px; /* Increased max-width */
  min-height: 55px;
  margin: 60px auto 5px; /* Combined margin properties for conciseness */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
}

  
  .url-input-box {
    background-color: #2C2C2E; /* Same as the container for seamless integration */
    font-size: 17px;
    border: none;
    color: white; /* Text color */
    margin-right: 10px; /* Spacing between the input box and the button */
    padding: 10px;
    padding-left: 40px;
    border-radius: 10px; /* Slightly rounded corners for the input box */
    flex-grow: 1; /* Ensures that the input box takes up the available space */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    outline: none; 
  }
  .url-input-box:focus {
    background-color: #3C3C3E; /* Brighter shade when input is focused */
    box-shadow: none; /* Remove any default browser styling like shadows or outlines */
  }
  .input-icon-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
  }

  .url-input-icon {
    position: absolute;
    left: 2px; /* Or adjust as needed */
    width: 30px; /* Your desired icon size */
    height: auto;
  }

  .url-input-box::placeholder {
    color: #5C5C5E; /* Lighter color for placeholder text */
  }
  
  .url-input-button {
    background-color: #0e0e0e; /* Default purple color for the button */
    color: white; /* Button text color */
    border: none;
    padding: 15px 20px;
    border-radius: 5px; /* More rounded corners for the button */
    font-weight: bold;
    cursor: pointer; /* Changes cursor to pointer when hovering over the button */
    font-size: 16px;
    text-align: center;
}

    .url-input-button.disabled {
        opacity: 0.5; /* Style for disabled state */
        cursor: default; 
      
      }


    .timestamps-generated {
        background-color: #45c072; /* Different color indicating timestamps are generated */
    }

    .timestamps-generatedfetch {
      background-color: #ffffff; /* Different color indicating timestamps are generated */
      color: #161616;
  }


    .processing {

      background-color: #ff8c00; /* Different color indicating search in progress */
      opacity: .8;
    }

    .max-duration-warning {
      background-color: #ff8c00; /* Different color indicating search in progress */
      cursor: default;

    }
    .searching {
        background-color: #0e0e0e; /* Different color indicating search in progress */
    }

    .timebank-over {
      text-decoration: none; /* Removes underline from the link */
      background-color: #40a465; /* Your existing background color */
      color: white;      
  }

  
  .url-input-button:hover {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  }










.upload-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Spacing between elements */
}

.file-drop-area {
    position: relative;
    border: 2px dashed #555;
    border-radius: 10px;
    padding: 20px;
    width: 55%;
    text-align: center;
    background-color: #161616;
}

.file-input-label {
    display: block; /* Make the label behave like a container */
    margin-top: 2px; /* Adjust the spacing to match the button's position */
    cursor: pointer;
    color: #888; /* Color for the text inside the label */
}

.video-type-section {
    background-color: #0e0e0e; /* Background color for the video types section */
    padding: 25px;
    margin-top: 5px;
    margin-right: auto; /* Centers the section */
    margin-left: auto; /* Centers the section */
    border-radius: 5px;
    text-align: left;
    width: 100%; /* Set width to enable horizontal centering */
    max-width: 420px;
    box-sizing: border-box; /* Ensures padding doesn't affect the total width */
    border: 1px solid #3e3e3e;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
}


.video-type-info p {
    color: #AAAAAA;
    margin-bottom: 20px;
    margin-top: 6px;
    font-size: 15px;
}

.video-types {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.supported-types p,
.unsupported-types p {
    margin: 5px 0;
    color: #FFFFFF;
    font-size: 14px;
}

.supported-types p::before {
    content: '✔ ';
    color: #4CAF50;
}

.unsupported-types p::before {
    content: '✖ ';
    color: #F44336;
}

/* VIDEO PREVIEW */
/* VIDEO PREVIEW */
/* VIDEO PREVIEW */


.youtube-video-details {
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 25px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: left;
  width: 100%; /* Full width to center content in the middle */
  box-sizing: border-box;
}

.video-card {
  border-radius: 15px;
  color: white;
  /* Set a max-width if you don't want it to stretch too wide */
  max-width: 400px;
  width: 100%; /* This will now take the max-width value */
  position: relative;
  overflow: hidden;

}
.video-thumbnail-container {
  position: relative;
  border-radius: 15px; /* Apply border-radius here */
  overflow: hidden; /* Add overflow hidden here to ensure child elements respect the border-radius */
}

.video-thumbnail {
  width: 100%;
  height: auto; /* Adjust height to maintain aspect ratio */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  object-fit: cover; /* This will cover the area without stretching the image */
}

.video-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 200, 148, 0.4); /* Orange color with 50% opacity */
  border-radius: inherit; /* Inherit border-radius from the parent, if any */
}

.error-video-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 85, 85, 0.2);
  border-radius: inherit; /* Inherit border-radius from the parent, if any */
}

.unauth-error-message-box {
  position: absolute; /* Absolute positioning within the relative container */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset by half of the element's width and height to truly center it */
  background-color: #0e0e0e;
  border-radius: 5px;
  padding: 17px;
  border: 2px solid #3e3e3e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%; /* You might need to adjust this based on your design */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  z-index: 3; /* Ensure it sits above other elements */
}


.unauth-error-message {
  font-size: 16px;
  font-weight: bold;
  margin: 5px;  /* Adjust the margin as needed */
  text-align: center;
}

.unauth-retry-message {
  font-size: 14px;
}

.unauth-retry-message a{
  color: #4add80;
  text-decoration: underline;

}

.processing-gif {
  width: 100px; /* Adjust to fit within your thumbnail */
  height: auto; /* Maintain aspect ratio */
  /* Other styles remain the same */
}


.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px; /* Match the thumbnail's border-radius */
}

.video-quality {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ffcc00;
  padding: 5px;
  border-radius: 4px;
  /* ...other styles... */
}

.unauth-video-title {
  position: absolute;
  bottom: 40px; /* Adjust as needed */
  left: 10px;
  /* ...other styles... */
}

.video-metadata {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.video-caption, .video-credit-usage {
  padding: 5px;
  font-size: 17px;
  color: #9b9ea3;
  /* ...other styles... */
}
.video-caption b, .video-credit-usage b {
  color: white; /* This will change the color of the text inside <b> tags to white */
}

  /* Vid-Not Found */
  /* Vid-Not Found */
  /* Vid-Not Found */
  /* Vid-Not Found */



.unauth-video-not-found {
  border-radius: 5px; /* More rounded corners */
  background-color: #0e0e0e;
  color: #FFFFFF; /* White text */
  padding: 24px; /* More padding for better spacing */
  text-align: center;
  margin: 10px auto; /* Centered horizontally */
  font-size: 18px; /* Font size */
  max-width: 400px; /* Max width to maintain readability */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4); /* Enhanced shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  border: 1px solid #3e3e3e;
  margin-top: 0px;
  /* Hover effect for interactivity */
  &:hover {
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-7px);
  }
}

  /* Main text styling */
  .unauth-main-text {
    font-size: 20px;
    font-weight: 600; /* Bold */
    margin-bottom: 15px; /* Spacing between main text and small text */
  }

  /* Small text styling */
  .unauth-small-text {
    font-size: 14px;
    line-height: 1.5; /* Improved readability */
    color: #B3B3B3; /* Lighter color for less emphasis */
  }

  .highlight-video-id {
    font-weight: bold;
    color: #3da6fe;
  }
  
  .unauth-example-url {
    font-size: 17px; /* Increased font size */
    display: block; /* Makes it a separate line */
    margin-top: 5px; /* Optional spacing */
    color: #ffffff;
  }



/* TOGGLE BAR */
/* TOGGLE BAR */

/* TOGGLE BAR */

.toggle-switch-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center everything in the pill */
  gap: 10px; /* Add some space between the text and the switch */
  background-color: #0e0e0e; /* Background color for the video types section */
  padding: 20px 20px; /* Adjust padding as needed */
  border-radius: 15px;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
 border: 1px solid #3e3e3e;

}

.toggle-switch-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}


.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #4add80;
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px #4add80;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.toggle-switch-text {
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  text-align: left;
  color: white;
}



.unauth-exit-button{
  background: none;
  margin-left: auto;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: white;

}
/* Base styles here */

@media (max-width: 768px) {
  .dashboard-container {
      width: 90%; /* Allow a little margin on the sides */
      padding: 5px; /* Reduce padding */
      margin: auto; /* Adjust top and bottom margins */
  }

  .urlinput-section {

    width: 90%;


}

  .upload-area {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .urlinput-container {
      flex-direction: column; /* Stack the elements */
      padding: 15px; /* Increase padding */
  }

  .input-icon-wrapper {
      margin-bottom: 10px; /* Add space between the icon wrapper and the button */
  }

  .url-input-box {
      padding-left: 15px; /* Adjust padding to align text nicely */
      font-size: 16px; /* Make the text a bit larger */
  }

  .url-input-icon {
    display: none;

  }

  .url-input-button {
      padding: 12px 15px; /* Increase padding for better touch targets */
      font-size: 16px; /* Slightly smaller font size to fit the button */
      white-space: nowrap; /* Prevent text from wrapping */
      min-width: 150px; /* Minimum width for the button */
      margin: 0 auto; /* Center the button */
      display: block; /* Make the button take the full width */
  }

  .file-drop-area {
      width: 100%; /* Full width to utilize space */
      padding: 15px; /* Adjust padding */
  }

  .video-type-section {
      width: 95%; /* Slightly smaller width to maintain some margin */
  }

  .video-types {
      flex-direction: column; /* Stack the lists vertically */
  }

  .video-type-info p,
  .supported-types p,
  .unsupported-types p {
      font-size: 16px; /* Increase font size for readability */
  }

  .toggle-switch-text {
    font-size: 3.1vw; /* Smaller vw value for smaller screens */
  }
}


body {
   /* background: linear-gradient(to right, #1f1f1f, #333);*/
    background-color: #161616;
    margin: 0;
    padding: 0;
}

.homepage-container {
    display: grid;
    grid-template-columns: 1fr 1.1fr; /* Two equal columns */
    align-items: center;
    max-width: 60%;  /* Limit the maximum width */
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}


.homepage-text {
    text-align: left;
    justify-self: end;
    max-width: 550px;  
    margin: 0 auto; 
}

.homepage-image {
    justify-self: start; /* Aligns the image to the start of its grid cell */
}

.homepage-image img {
    max-width: 90%;
    height: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    border-radius: 25px;
}

.top-line {
    font-size: 1.1em;
    color: #13c491; 
    margin-bottom: 20px;
    text-align: left;
}

.homepage-container h1 {
    font-size: 3.1em;
    color: #FFFFFF;
    margin-bottom: 30px;
    font-weight: bold;
}

.homepage-container p {
    font-size: 1.0em;
    color: #CCCCCC;
    max-width: 95%;
}

.CTA-button-home {
    padding: 15px 20px;
    font-size: 1.2em;
    background-color: #13c491;
    color: #FFF;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.CTA-button-home:hover {
    background-color: #3da6fe;
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.helpcenter-button {
  padding: 15px 20px;
  font-size: 1.2em;
  background-color: #ffff;
  color: #161616;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.helpcenter-button:hover {
  /* background-color: #3da6fe; */
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.button-icon {
  margin-left: 4px; /* Adds some space between the text and the image */
  width: 30px; /* Example width, adjust as needed */
  height: auto; /* Keeps the aspect ratio of the image */
}

/* Processing CSS */
/* Processing CSS */
/* Processing CSS */

.processing-container {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Optional */
}



.processing-text {
    color: white;
    text-align: center;
    font-size: 20px;
  }
  .processing-image {
    width: 100px;
    height: auto;
  }
  .fixed-text {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }

  .powered-by-container {
    display: flex;
    align-items: center; /* Vertically centers the items in the container */
}

.powered-by-text {
    /* Add your styles for the text here */
    margin-right: 8px; /* Adjust the right margin to create space between the text and the image */
    line-height: 1.5;
}

.emphasis {
  /* Add your styles for this span here */
  color: #FFF;
  font-weight: bold; /* Example style */
}


.openai-logo {
    width: 80px; /* Adjust width as needed */
    height: auto; /* Keeps the aspect ratio intact */
    margin-left: 0.4em;
    vertical-align: middle;
}

.bumpups-logo {
  width: 30px; /* Adjust width as needed */
  height: 30px; /* Keeps the aspect ratio intact */
  vertical-align: middle;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}


/* EMBEDDED VIDEO */

.youtube-video-container {
  position: relative;
  width: 50%; /* Set the width to 50% of the screen */
  margin: 0 auto; /* Center the container */
  margin-top: 100px;
  padding-bottom: 28.125%; /* Adjust the padding to maintain aspect ratio */
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* Ensures the iframe corners are also rounded */
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px; /* Rounded corners for iframe */
}





/* FAQ Section */
.faq-section {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .faq-section h1 {
    color: white;
    margin-bottom: 30px;
    font-size: 2.5em;
  }
  
  .faq-item {
    width: 80%;
    background-color: #0e0e0e;  /* Dark background */
    margin-bottom: 10px;
    border-radius: 30px;  /* Rounded corners */
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);  /* Shadow for depth */
    padding: 10px;
  }
  
  .faq-item h3 {
    margin: 0;
    padding: 15px;
    text-align: left;
    font-weight: bold;
    color: white;
    cursor: pointer;  /* Hand cursor on hover */
    border-radius: 30px; 
  }
  
  .faq-item h3:hover {
    background-color: #121111;  /* Slightly darker background on hover */
    
  }
  
  .faq-content {
    max-height: 0;  /* Start off closed */
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;  /* Smooth transition */
    background-color: #0e0e0e;  /* Slightly lighter than the question background */
    color: white;
    padding: 0 15px;
    border-bottom-left-radius: 20px;  /* Rounded bottom-left corner */
    border-bottom-right-radius: 20px;
    text-align: left;
    font-size: 1.2em;

  }
  
  .faq-content.show {
    max-height: 200px;  /* Max height when open */
    padding: 15px;  /* Padding when open */
  }



  .fade-in-out {
    animation: fade-in-out 2.5s infinite;
  }
  
  @keyframes fade-in-out {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  


/* VALUE EXAMPLE */

/* VALUE EXAMPLE */

/* VALUE EXAMPLE */


.valueExamples {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px;
  margin-top: 150px;
  background: #0e0e0e;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);

}

.valueExamples-text {
  display: flex; /* Enables flexbox */
  flex-direction: column; /* Stacks children vertically */
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Centers children vertically, if needed */
  background: #0e0e0e;
  padding: 15px; /* Spacing inside */
  margin-bottom: 10px; /* Spacing between text and image */
}

.valueExamples-image img {
  max-width: 60%;
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  margin-bottom: -100px; /* Overlap the image slightly on the text block */
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.valueExamples-image img:hover {
  transform: scale(1.05);

}
.seamless-integration-title {
  
  color: white;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;
}

.seamless-integration-text {
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  text-align: left;
}

/* New class for YouTube logo */
.youtube-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between text and logo */
  margin-bottom: 20px;
}

.youtube-logohomepage {
  height: 100px; /* Adjust as needed */
  width: 100px;
  margin-top: 15px;
}




  /* Processing CSS */
  /* Processing CSS */
  /* Processing CSS */
  
/* Mobile optimization */
@media (max-width: 768px) {
    .homepage-container {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  
    .homepage-text {
      font-size: 20px;
      text-align: center;
      max-width: 100%;
    }

    .powered-by-text {
      margin-top: -5px;
      font-size: 14px !important;

    }
    .top-line {
      font-size: 14px;
      text-align: center;
      max-width: 100%;

    }
    .homepage-image {
      margin-top: 20px;
      max-width: 100%;
      min-width: 320px;
      height: auto;

    }
    .homepage-container h1 {
        font-size: 1.5em;
    }
    .faq-section {
      width: 90%;  /* Adjust this value as needed */
    }

    .processing-text {
      font-size: 1.0em;
    }

    .CTA-button-home {
      font-size: 16px;
      padding: 10px 15px;
      margin: 10px auto;
      background-color: #13c491;
    }
    
    .CTA-button-home:hover {
      background-color: #3da6fe;
      transform: scale(1.05);
  }

  .youtube-video-container {
    margin-top: 50px;
    width: 80%; /* Increase width for mobile screens */
    padding-bottom: 45%; /* Adjust padding-bottom to maintain aspect ratio */
}

      .valueExamples {
        flex-direction: column;
        margin-top: 80px; /* Reduced top margin for smaller screens */
        margin-bottom: 100px;
      }

      .valueExamples-text {
        padding: 10px; /* Slightly reduced padding */
        margin-bottom: 0px; /* Increased space between text and image */
      }

      .valueExamples-image img {
        max-width: 80%; /* Increased max-width for better visibility */
        border-radius: 14px;
        margin-bottom: -50px; /* Reduced overlap for smaller screens */
      }

      .seamless-integration-title {
        font-size: 24px; /* Reduced font size */
      }

      .youtube-logohomepage {
        height: 80px; /* Reduced logo size */
        width: 80px; /* Reduced logo size */
      }

  }

  /* Tablet optimization */
@media (min-width: 769px) and (max-width: 1280px) {
    .homepage-container {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .homepage-text, .top-line {
      text-align: center;
      max-width: 100%;
    }
    .homepage-image {
      margin-top: 40px;


    }

    .homepage-container h1 {
        font-size: 3.0em;
    }

    .homepage-container p {
        font-size: 0.9em;
    }

    .CTA-button-home {
        font-size: 1.1em;
        margin-left: auto; /* Centers the button horizontally */
        margin-right: auto;
    }
    .helpcenter-button {
      font-size: 1.1em;
      margin-left: auto; /* Centers the button horizontally */
      margin-right: auto;
  }

  .valueExamples {
    flex-direction: column;
    margin-top: 80px; /* Reduced top margin for smaller screens */
    margin-bottom: 100px;
  }


    .faq-section {
      width: 70%;  /* Adjust this value as needed */
    }

}


/* DEBUGGING WORK */


.debug-button {
  margin: 10px;
  padding: 10px;
  background-color: #45494d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


  
.creatordashboard {
  display: flex;
  background-color: #161616;
  min-height: 100vh;
}


.creator-dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.notification-box {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  background: #161616;
  color: #ffffff;
  border-radius: 5px;
  padding: 12px 24px;
  margin-top: 40px;
  margin-bottom: 20px;
  border: 1px solid #3e3e3e;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  font-weight: bold;
  text-align: center;
  width: 400px;
}

.notification-box img.notification-logo {
  margin-right: 16px; /* Add some space between the logo and the text */
  width: 20px; /* Adjust the size as needed */
  height: auto;
}

.notification-box a {
  color: #4add80;
  text-decoration: none;
  font-weight: bold;
  padding-left: 8px;
}


/* For Mobile */
@media screen and (max-width: 768px) {

  .dashboard-content {
    margin-top: 0;
    width: 100%;
    padding: 0 10px; 
  }

  .notification {
    padding: 10px 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  

}

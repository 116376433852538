.footer-container {
    display: flex;
    justify-content: center;
    background-color: #161616;
    padding: 20px 40px;
    font-size: 14px;
    color: #606060;
    margin-top: 50px;
    flex-wrap: wrap; /* Allow content to wrap to the next line */
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 70px;
}

.footer-bottom {
    margin-top: 5px;
    font-size: 15px;
    color: #cccccc;
    text-align: center;
    width: 100%; /* Ensure it takes the full width */
    font-weight: bold;
    padding-left: 50px;
    padding-bottom: 30px;
}

.footer-bottom a {
    margin-left: 10px;
    margin-right: 10px;
    color: #cccccc;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
    color: #13c491;
}

.contact-link {
    text-decoration: none;
    color: #CCCCCC !important;
}

.contact-link:hover {
    text-decoration: none;
    color: #13c491 !important;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-bottom {
        font-size: 12px;
        text-align: center;
        padding-left: 0;
    }
}
